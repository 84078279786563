export class ApiUrlConfig {
    public static DecryptValidateTokenUrl: string = 'api/v1/Auth/DecryptValidateToken';
    public static GetRedirectURL: string = 'api/V1/Envelope/GetRedirectURL';
    public static GetEnvelopeDetailsUrl: string = 'api/v1/Envelope/GetEnvelopeDetails';
    public static GetTemplateDetailsUrl: string = 'api/v1/Template/GetTemplateDetails';
    public static GetEnvelopeImages: string = 'api/v1/Envelope/GetImages';
    public static GetTemplateImages: string = 'api/v1/Template/GetImages';
	public static GetEnvelopeSettingDetailsUrl: string = 'api/v1/Envelope/GetEnvelopeSettingsDetails';
    public static GetTemplateSettingDetailsUrl: string = 'api/v1/Template/GetTemplateSettingsDetails';
	public static SendEnvelope: string = 'api/v1/Envelope/SendEnvelope';
    public static GetLanguageTranslationsUrl: string = 'api/v1/Auth/GetLanguageKeyDetails';
    public static GetUserPlanDetails: string = 'api/v1/User/GetUserPlanDetails';
    public static DiscardEnvelope: string = 'api/v1/Envelope/Discard';
    public static SaveAsDraftEnvelope: string = 'api/v1/Envelope/SaveDraftEnvelope';
    public static ViewPdf:string='api/v1/Pdf/ViewPdf';
    public static RefreshToken:string='api/v1/Auth/RefreshToken';
    public static OpenEnvelope: string = 'api/v1/Envelope/OpenEnvelope';
    public static SaveDefaultSettings: string = 'api/v1/Settings/SaveSettings';
    public static SaveAsTemplate: string = 'api/v1/Template/SaveTemplateOrRule';
    public static ViewTemplatePdf:string = 'api/v1/Pdf/ViewTemplatePdf';
    public static GetMultibrandingByCompanyId: string = 'api/v1/Settings/GetMultibrandingByCompanyId';
    public static GetEnvelopeDocumentImageDetailsUrl: string = 'api/v1/Envelope/GetDocumentImageDetails';
    public static GetTemplateDocumentImageDetailsUrl: string = 'api/v1/Template/GetDocumentImageDetails';
}

export class NavigateUrlConfig {
    public static Home: string = 'Home/Index';
    public static Send: string = 'Envelope/EnvelopeIndex';
    public static Envelope: string = 'DocumentPackage/DocumentPackageIndex';
    public static Template: string = 'Template/Index';
    public static Settings: string = 'Settings/Index';
    public static Translations: string = 'Translations/Index';
    public static UserDetails: string = 'Settings/UserDetails';
    public static Stats: string = 'Home/Stats';
    public static LogOff: string = 'Account/LogOff';
    public static GetOutOfOfficeDetails: string = 'Settings/GetOutOfOfficeDetails';
    public static LogOn: string = 'Account/LogOn';
    public static DocumentPackage: string = 'DocumentPackage/DocumentPackageList';
}
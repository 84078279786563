<div class="modal-content">
    <div class="modal-header">
        <h5 class="modal-title font-w600" id="staticBackdropLabel">View Settings</h5>
        <div class="modalposition" aria-label="Close"> <span class="material-icons model-closeicon"
                (click)="modalRef.hide()">close</span></div>
    </div>
    <div class="modal-body">
        <div class="d-flex align-items-center justify-content-between mb-3">
            <div class="input-group w-50">
                <input type="text" autocomplete="off" class="form-control search" id="searchtext" style="padding-left:1rem;"
                    aria-describedby="searchtext" placeholder="Search Settings" (keyup.enter)="onFilterApply()"
                    (keyup)="onFilterApply()" />
                <div class="input-group-append">
                    <span class="input-group-text"><i class="fa fa-search" (click)="onFilterApply()"></i></span>
                </div>
                <!-- <input autocomplete="off" type="search" id="example-search-input" class="form-control search" placeholder="Search Fields" aria-label="Search Fields"> -->
            </div>

            <div class="d-flex align-items-center w-30">
                <label for="exampleFormControlInput1" class=" form-label text-nowrap mb-0 mx-3">Filter
                    By</label>
                <select class="form-select controlshadow" aria-label="Default select example"
                    (change)="applyFilterCondition($event)">
                    <option value="all">None</option>
                    <option value="general">Sending</option>
                    <option value="advanced">Advanced</option>
                    <option value="admin">Admin</option>
                    <option value="system">System</option>
                    <option value="privacy">Privacy</option>
                </select>
            </div>
        </div>

        <!-- #f2f5f7; -->
        <div class="setting-header d-flex align-items-center">
            <label class="w-75 ps-1" type="button">
                Setting Name
            </label>
            <label class="w-25" style="padding-left: 3.25rem;" type="button">
                Value
            </label>
        </div>
        <div class="table-scroll" *ngIf="isResponseLoaded == true">
            <div class="accordion-body pb-0 px-1" *ngIf="filterBy == 'general' || filterBy == 'all' ">
                <table class="w-100 setting-table table-space">
                    <tr class="tablehead w-100">
                        <!-- <th class="ps-4" colspan="2" class="">{{getLanguageTranslationValue(resourceKeys.GeneralSettings)}}</th> -->
                        <th class="ps-4" colspan="2" class="">SENDING</th>
                    </tr>
                </table>
                <div class="w-100">
                    <table class="w-100 setting-table">
                        <tr class="controlshadow">
                            <td class="w-75 ps-3">
                                <label>{{getLanguageTranslationValue(resourceKeys.DateFormat)}}</label>
                            </td>
                            <td class="w-25 left-space"> {{envelopeAdditionalDetails.dateFormat}} </td>
                        </tr>

                        <tr class="controlshadow">
                            <td class="w-75 ps-3">
                                <label>{{getLanguageTranslationValue(resourceKeys.ExpiresIn)}}</label>
                            </td>
                            <td class="w-25 left-space"> {{envelopeAdditionalDetails.expiresIn}} </td>
                        </tr>

                        <tr class="controlshadow">
                            <td class="w-75 ps-3">
                                <label>{{getLanguageTranslationValue(resourceKeys.SendFirstReminderIn)}}</label>
                            </td>
                            <td class="w-25 left-space"> {{customSetting.sendReminderInstr}} </td>
                        </tr>

                        <tr class="controlshadow">
                            <td class="w-75 ps-3">
                                <label>{{getLanguageTranslationValue(resourceKeys.ThenSendFollowUpRemindersEvery)}}</label>
                            </td>
                            <td class="w-25 left-space"> {{customSetting.thenReminderInstr}} </td>
                        </tr>

                        <tr class="controlshadow">
                            <td class="w-75 ps-3">
                                <label>{{getLanguageTranslationValue(resourceKeys.SendFinalReminderBeforeExpiration)}}</label>
                            </td>
                            <td class="w-25 left-space"> {{customSetting.finalReminderDaysInstr}} </td>
                        </tr>

                        <tr class="controlshadow">
                            <td class="w-75 ps-3">
                                <label>{{getLanguageTranslationValue(resourceKeys.EnvelopeExpirationRemindertoSender)}}</label>
                            </td>
                            <td class="w-25 left-space"
                                *ngIf="envelopeAdditionalDetails.isEnvelopeExpirationRemindertoSender == 1">
                                {{getLanguageTranslationValue(resourceKeys.lang_Y)}}
                                {{customSetting.senderReminderDays}}</td>
                            <td class="w-25 left-space"
                                *ngIf="envelopeAdditionalDetails.isEnvelopeExpirationRemindertoSender != 1">
                                {{getLanguageTranslationValue(resourceKeys.lang_N)}} </td>
                        </tr>

                        <tr class="controlshadow">
                            <td class="w-75 ps-3">
                                <label>{{getLanguageTranslationValue(resourceKeys.SendReminderTillExpiration)}}</label>
                            </td>
                            <td class="w-25 left-space"
                                *ngIf="envelopeAdditionalDetails.isSendReminderTillExpiration == 1">
                                {{getLanguageTranslationValue(resourceKeys.lang_Y)}}
                                {{customSetting.finalSendReminderTillExpiration}}</td>
                            <td class="w-25 left-space"
                                *ngIf="envelopeAdditionalDetails.isSendReminderTillExpiration != 1">
                                {{getLanguageTranslationValue(resourceKeys.lang_N)}} </td>
                        </tr>

                        <tr class="controlshadow">
                            <td class="w-75 ps-3">
                                <label>{{getLanguageTranslationValue(resourceKeys.AccessAuthentication)}}</label>
                            </td>
                            <td class="w-25 left-space"
                                *ngIf="envelopeSettingDetails.accessAuthenticationId != passwordType.Select">
                                {{envelopeAdditionalDetails.accessAuthentication}} </td>
                            <td class="w-25 left-space"
                                *ngIf="envelopeSettingDetails.accessAuthenticationId == passwordType.Select">
                                {{getLanguageTranslationValue(resourceKeys.lang_none)}} </td>
                        </tr>

                        <tr class="controlshadow"
                            *ngIf="envelopeAdditionalDetails.enableSendingMessagesToMobile != true">
                            <td class="w-75 ps-3">
                                <label>{{getLanguageTranslationValue(resourceKeys.EmailAccessCode)}}</label>
                            </td>
                            <td class="w-25 left-space"
                                *ngIf="(envelopeSettingDetails.accessAuthenticationId == passwordType.Endtoend.toLowerCase() || envelopeSettingDetails.accessAuthenticationId == passwordType.RequiredToOpenSigned.toLowerCase()) && envelopeSettingDetails.isAccessCodeSendToSignerEnabled == true ">
                                {{getLanguageTranslationValue(resourceKeys.Checked)}}
                            </td>
                            <td class="w-25 left-space"
                                *ngIf="!((envelopeSettingDetails.accessAuthenticationId == passwordType.Endtoend.toLowerCase() || envelopeSettingDetails.accessAuthenticationId == passwordType.RequiredToOpenSigned.toLowerCase()) && envelopeSettingDetails.isAccessCodeSendToSignerEnabled == true) ">
                                {{getLanguageTranslationValue(resourceKeys.Unchecked)}} </td>
                        </tr>

                        <tr class="controlshadow">
                            <td class="w-75 ps-3" *ngIf="source == 'template'">
                                <label>{{getLanguageTranslationValue(resourceKeys.IncludeStaticTemplates)}}</label>
                            </td>
                            <td class="w-75 ps-3" *ngIf="source == 'envelope'">
                                <label>{{getLanguageTranslationValue(resourceKeys.RequiresSignersConfirmationonFinalSubmit)}}</label>
                            </td>
                            <td class="w-25 left-space"
                                *ngIf="envelopeAdditionalDetails.requiresSignersConfirmationonFinalSubmit != null && envelopeAdditionalDetails.requiresSignersConfirmationonFinalSubmit == true">
                                {{getLanguageTranslationValue(resourceKeys.lang_Y)}} </td>
                            <td class="w-25 left-space"
                                *ngIf="!(envelopeAdditionalDetails.requiresSignersConfirmationonFinalSubmit != null && envelopeAdditionalDetails.requiresSignersConfirmationonFinalSubmit == true)">
                                {{getLanguageTranslationValue(resourceKeys.lang_N)}} </td>
                        </tr>

                        <tr class="controlshadow">
                            <td class="w-75 ps-3">
                                <label>{{getLanguageTranslationValue(resourceKeys.SendIndividualNotifications)}}</label>
                            </td>
                            <td class="w-25 left-space"
                                *ngIf="envelopeSettingDetails.sendIndividualSignatureNotifications == true">
                                {{getLanguageTranslationValue(resourceKeys.lang_Y)}} </td>
                            <td class="w-25 left-space"
                                *ngIf="!(envelopeSettingDetails.sendIndividualSignatureNotifications == true)">
                                {{getLanguageTranslationValue(resourceKeys.lang_N)}} </td>
                        </tr>

                        <tr class="controlshadow">
                            <td class="w-75 ps-3">
                                <label>{{getLanguageTranslationValue(resourceKeys.AddSignatureCertificate)}}</label>
                            </td>
                            <td class="w-25 left-space"
                                *ngIf="envelopeSettingDetails.includeSignedCertificateOnSignedPDF == true">
                                {{getLanguageTranslationValue(resourceKeys.lang_Y)}} </td>
                            <td class="w-25 left-space"
                                *ngIf="!(envelopeSettingDetails.includeSignedCertificateOnSignedPDF == true)">
                                {{getLanguageTranslationValue(resourceKeys.lang_N)}} </td>
                        </tr>

                        <tr class="controlshadow">
                            <td class="w-75 ps-3">
                                <label>{{getLanguageTranslationValue(resourceKeys.Sequence)}}</label>
                            </td>
                            <td class="w-25 left-space" *ngIf="signInSequenceDesc == true">
                                {{getLanguageTranslationValue(resourceKeys.lang_Y)}} </td>
                            <td class="w-25 left-space" *ngIf="signInSequenceDesc == false">
                                {{getLanguageTranslationValue(resourceKeys.lang_N)}} </td>
                        </tr>

                        <tr class="controlshadow">
                            <td class="w-75 ps-3">
                                <label>{{getLanguageTranslationValue(resourceKeys.DownloadLinkOnManage)}}</label>
                            </td>
                            <td class="w-25 left-space" *ngIf="envelopeSettingDetails.storedSignedPDF == true">
                                {{getLanguageTranslationValue(resourceKeys.lang_Y)}} </td>
                            <td class="w-25 left-space" *ngIf="!(envelopeSettingDetails.storedSignedPDF == true)">
                                {{getLanguageTranslationValue(resourceKeys.lang_N)}} </td>
                        </tr>

                        <tr class="controlshadow">
                            <td class="w-75 ps-3">
                                <label>{{getLanguageTranslationValue(resourceKeys.IncludeTransparencyDocument)}}</label>
                            </td>
                            <td class="w-25 left-space" *ngIf="envelopeSettingDetails.includeTransparencyDoc == true">
                                {{getLanguageTranslationValue(resourceKeys.lang_Y)}} </td>
                            <td class="w-25 left-space"
                                *ngIf="!(envelopeSettingDetails.includeTransparencyDoc == true)">
                                {{getLanguageTranslationValue(resourceKeys.lang_N)}} </td>
                        </tr>

                        <tr class="controlshadow">
                            <td class="w-75 ps-3">
                                <label>{{getLanguageTranslationValue(resourceKeys.SignerAttachmentOptions)}}</label>
                            </td>
                            <td class="w-25 left-space"> {{envelopeAdditionalDetails.signerAttachmentOptions}} </td>
                        </tr>

                        <tr class="controlshadow" *ngIf="this.envelopeSettingDetails.includeSignerAttachFile == 1">
                            <td class="w-75 ps-3">
                                <label>{{getLanguageTranslationValue(resourceKeys.AdditionalAttachments)}}</label>
                            </td>
                            <td class="w-25 left-space" *ngIf="additionalAttachments == ''"> N/A </td>
                            <td class="w-25 left-space"
                                *ngIf="additionalAttachments == 'true'">
                                {{getLanguageTranslationValue(resourceKeys.lang_Y)}} </td>
                            <td class="w-25 left-space"
                                *ngIf="additionalAttachments == 'false'">
                                {{getLanguageTranslationValue(resourceKeys.lang_N)}} </td>
                        </tr>

                        <tr class="controlshadow"
                            *ngIf="envelopeSettingDetails.includeCreateStaticLink && currentSetting.includeCreateStaticLink">
                            <td class="w-75 ps-3">
                                <label>{{getLanguageTranslationValue(resourceKeys.IsDefaultSignatureRequiredForStaticTemplate)}}</label>
                            </td>
                            <td class="w-25 left-space"
                                *ngIf="envelopeSettingDetails.includeDefaultSignReqForStaticTemplate == true">
                                {{getLanguageTranslationValue(resourceKeys.lang_Y)}} </td>
                            <td class="w-25 left-space"
                                *ngIf="!(envelopeSettingDetails.includeDefaultSignReqForStaticTemplate == true)">
                                {{getLanguageTranslationValue(resourceKeys.lang_N)}} </td>
                        </tr>

                        <tr class="controlshadow">
                            <td class="w-75 ps-3">
                                <label>{{getLanguageTranslationValue(resourceKeys.SendConfirmationEmail)}}</label>
                            </td>
                            <td class="w-25 left-space" *ngIf="envelopeSettingDetails.sendConfirmationEmail == true">
                                {{getLanguageTranslationValue(resourceKeys.lang_Y)}} </td>
                            <td class="w-25 left-space" *ngIf="!(envelopeSettingDetails.sendConfirmationEmail == true)">
                                {{getLanguageTranslationValue(resourceKeys.lang_N)}} </td>
                        </tr>

                        <tr class="controlshadow">
                            <td class="w-75 ps-3">
                                <label>{{getLanguageTranslationValue(resourceKeys.SeparateMultipleDocumentsAfterSigning)}}</label>
                            </td>
                            <td class="w-25 left-space"
                                *ngIf="envelopeSettingDetails.separateMultipleDocumentsAfterSigning == true">
                                {{getLanguageTranslationValue(resourceKeys.lang_Y)}} </td>
                            <td class="w-25 left-space"
                                *ngIf="!(envelopeSettingDetails.separateMultipleDocumentsAfterSigning == true)">
                                {{getLanguageTranslationValue(resourceKeys.lang_N)}} </td>
                        </tr>

                        <tr class="controlshadow">
                            <td class="w-75 ps-3">
                                <label>{{getLanguageTranslationValue(resourceKeys.AttachXMLDocument)}}</label>
                            </td>
                            <td class="w-25 left-space" *ngIf="envelopeSettingDetails.includeAddXMLData == true">
                                {{getLanguageTranslationValue(resourceKeys.lang_Y)}} </td>
                            <td class="w-25 left-space" *ngIf="!(envelopeSettingDetails.includeAddXMLData == true)">
                                {{getLanguageTranslationValue(resourceKeys.lang_N)}} </td>
                        </tr>

                        <tr class="controlshadow">
                            <td class="w-75 ps-3">
                                <label>{{getLanguageTranslationValue(resourceKeys.StoreSignatureCertificate)}}</label>
                            </td>
                            <td class="w-25 left-space"
                                *ngIf="envelopeSettingDetails.storeSignatureCertificate != null && envelopeSettingDetails.storeSignatureCertificate == true">
                                {{getLanguageTranslationValue(resourceKeys.lang_Y)}} </td>
                            <td class="w-25 left-space"
                                *ngIf="!(envelopeSettingDetails.storeSignatureCertificate != null && envelopeSettingDetails.storeSignatureCertificate == true)">
                                {{getLanguageTranslationValue(resourceKeys.lang_N)}} </td>
                        </tr>

                        <tr class="controlshadow">
                            <td class="w-75 ps-3">
                                <label>{{getLanguageTranslationValue(resourceKeys.DeleteOriginalDocument)}}</label>
                            </td>
                            <td class="w-25 left-space" *ngIf="envelopeSettingDetails.deleteOriginalDocument == true">
                                {{getLanguageTranslationValue(resourceKeys.lang_Y)}} </td>
                            <td class="w-25 left-space"
                                *ngIf="!(envelopeSettingDetails.deleteOriginalDocument == true)">
                                {{getLanguageTranslationValue(resourceKeys.lang_N)}} </td>
                        </tr>

                        <tr class="controlshadow">
                            <td class="w-75 ps-3">
                                <label>{{getLanguageTranslationValue(resourceKeys.StoreOriginalDocument)}}</label>
                            </td>
                            <td class="w-25 left-space"
                                *ngIf="envelopeSettingDetails.storeOriginalDocument != null && envelopeSettingDetails.storeOriginalDocument == true">
                                {{getLanguageTranslationValue(resourceKeys.lang_Y)}} </td>
                            <td class="w-25 left-space"
                                *ngIf="!(envelopeSettingDetails.storeOriginalDocument != null && envelopeSettingDetails.storeOriginalDocument == true)">
                                {{getLanguageTranslationValue(resourceKeys.lang_N)}} </td>
                        </tr>

                        <tr class="controlshadow">
                            <td class="w-75 ps-3">
                                <label>{{getLanguageTranslationValue(resourceKeys.EnableFileReview)}}</label>
                            </td>
                            <td class="w-25 left-space" *ngIf="envelopeSettingDetails.enableFileReview == true">
                                {{getLanguageTranslationValue(resourceKeys.lang_Y)}} </td>
                            <td class="w-25 left-space" *ngIf="!(envelopeSettingDetails.enableFileReview == true)">
                                {{getLanguageTranslationValue(resourceKeys.lang_N)}} </td>
                        </tr>

                        <tr class="controlshadow">
                            <td class="w-75 ps-3">
                                <label>{{getLanguageTranslationValue(resourceKeys.EnableAutoFillTextControls)}}</label>
                            </td>
                            <td class="w-25 left-space"
                                *ngIf="envelopeSettingDetails.enableAutoFillTextControls != null && envelopeSettingDetails.enableAutoFillTextControls == true">
                                {{getLanguageTranslationValue(resourceKeys.lang_Y)}} </td>
                            <td class="w-25 left-space"
                                *ngIf="!(envelopeSettingDetails.enableAutoFillTextControls != null && envelopeSettingDetails.enableAutoFillTextControls == true)">
                                {{getLanguageTranslationValue(resourceKeys.lang_N)}} </td>
                        </tr>

                        <tr class="controlshadow">
                            <td class="w-75 ps-3">
                                <label>{{getLanguageTranslationValue(resourceKeys.HideResipientDetailsInEmail)}}</label>
                            </td>
                            <td class="w-25 left-space"
                                *ngIf="envelopeSettingDetails.hideOthersEmailAddresses == true">
                                {{getLanguageTranslationValue(resourceKeys.lang_Y)}} </td>
                            <td class="w-25 left-space"
                                *ngIf="!(envelopeSettingDetails.hideOthersEmailAddresses == true)">
                                {{getLanguageTranslationValue(resourceKeys.lang_N)}} </td>
                        </tr>
                    </table>
                </div>
            </div>
            <div class="accordion-body pb-0 px-1" *ngIf="filterBy == 'advanced' || filterBy == 'all' ">
                <table class="w-100 setting-table table-space">
                    <tr class="tablehead w-100">
                        <!-- <th class="ps-3" colspan="2" class="">{{getLanguageTranslationValue(resourceKeys.AdvancedSettings)}}</th> -->
                        <th class="ps-3" colspan="2" class="">ADVANCED SETTINGS</th>
                    </tr>
                </table>
                <div class="w-100">
                    <table class="w-100 setting-table">
                        <tr class="controlshadow">
                            <td class="w-75 ps-3">
                                <label>{{getLanguageTranslationValue(resourceKeys.FinalContractConversion)}}</label>
                            </td>
                            <td class="w-25 left-space"
                                *ngIf="envelopeSettingDetails.finalContractOptionID == finalContractOptions.Aspose">
                                Aspose </td>
                            <td class="w-25 left-space"
                                *ngIf="envelopeSettingDetails.finalContractOptionID == finalContractOptions.FromImages">
                                Images </td>
                            <td class="w-25 left-space"
                                *ngIf="envelopeSettingDetails.finalContractOptionID == finalContractOptions.iText">
                                iText </td>
                        </tr>

                        <tr class="controlshadow">
                            <td class="w-75 ps-3">
                                <label>{{getLanguageTranslationValue(resourceKeys.AttachSignedPdf)}}</label>
                            </td>
                            <td class="w-25 left-space"> {{envelopeAdditionalDetails.attachSignedPdf}} </td>
                        </tr>

                        <tr class="controlshadow">
                            <td class="w-75 ps-3">
                                <label>{{getLanguageTranslationValue(resourceKeys.EnableCcOptions)}}</label>
                            </td>
                            <td class="w-25 left-space" *ngIf="envelopeAdditionalDetails.enableCcOptions == true">
                                {{getLanguageTranslationValue(resourceKeys.lang_Y)}} </td>
                            <td class="w-25 left-space" *ngIf="!(envelopeAdditionalDetails.enableCcOptions == true)">
                                {{getLanguageTranslationValue(resourceKeys.lang_N)}} </td>
                        </tr>

                        <tr class="controlshadow">
                            <td class="w-75 ps-3">
                                <label>{{getLanguageTranslationValue(resourceKeys.ElectronicSignatureIndication)}}</label>
                            </td>
                            <td class="w-25 left-space"> {{envelopeAdditionalDetails.electronicSignatureOption}} </td>
                        </tr>

                        <tr class="controlshadow">
                            <td class="w-75 ps-3">
                                <label>{{getLanguageTranslationValue(resourceKeys.EnableRecipientLanguage)}}</label>
                            </td>
                            <td class="w-25 left-space"
                                *ngIf="envelopeAdditionalDetails.enableRecipientLanguage == true">
                                {{getLanguageTranslationValue(resourceKeys.lang_Y)}} </td>
                            <td class="w-25 left-space"
                                *ngIf="!(envelopeAdditionalDetails.enableRecipientLanguage == true)">
                                {{getLanguageTranslationValue(resourceKeys.lang_N)}} </td>
                        </tr>

                        <tr class="controlshadow">
                            <td class="w-75 ps-3">
                                <label>{{getLanguageTranslationValue(resourceKeys.EnableSendingMessagesToMobile)}}</label>
                            </td>
                            <td class="w-25 left-space"
                                *ngIf="envelopeAdditionalDetails.enableSendingMessagesToMobile == true">
                                {{getLanguageTranslationValue(resourceKeys.lang_Y)}} </td>
                            <td class="w-25 left-space"
                                *ngIf="!(envelopeAdditionalDetails.enableSendingMessagesToMobile == true)">
                                {{getLanguageTranslationValue(resourceKeys.lang_N)}} </td>
                        </tr>
                        <!-- branding setting -->
                        <tr class="controlshadow">
                            <td class="w-75 ps-3">
                                <label>{{getLanguageTranslationValue(resourceKeys.EnableMultipleBranding)}}</label>
                            </td>
                            <td class="w-25 left-space"
                                *ngIf="envelopeAdditionalDetails.enableMultipleBranding == true">
                                {{getLanguageTranslationValue(resourceKeys.lang_Y)}} </td>
                            <td class="w-25 left-space"
                                *ngIf="!(envelopeAdditionalDetails.enableMultipleBranding == true)">
                                {{getLanguageTranslationValue(resourceKeys.lang_N)}} </td>
                        </tr>
                        <tr class="controlshadow"
                            *ngIf="envelopeAdditionalDetails.enableMultipleBranding == true && envelopeAdditionalDetails.brandName">
                                <td class="w-75 ps-3">
                                    <label>Selected Brand Name</label>
                                </td>
                                <td class="w-25 left-space"> {{envelopeAdditionalDetails.brandName}} </td>
                        </tr>
                        <tr class="controlshadow"
                            *ngIf="envelopeAdditionalDetails.enableMultipleBranding == true && envelopeAdditionalDetails.brandLogo">
                            <td class="w-75 ps-3">
                                <label>Brand Image</label>
                            </td>
                            <td class="w-25 left-space">
                                <img src="{{envelopeAdditionalDetails.brandLogo}}" alt="brand ogo" style="max-height: 100px; max-width: 100px;"/>
                            </td>
                        </tr>
                        <tr class="controlshadow">
                            <td class="w-75 ps-3">
                                <label>{{getLanguageTranslationValue(resourceKeys.SignatureCertificateType)}}</label>
                            </td>
                            <td class="w-25 left-space"
                                *ngIf="envelopeSettingDetails.signatureCertificateType == 1 || envelopeSettingDetails.signatureCertificateType == 0">
                                {{getLanguageTranslationValue(resourceKeys.Old)}}</td>
                            <td class="w-25 left-space"
                                *ngIf="envelopeSettingDetails.signatureCertificateType == 2">
                                {{getLanguageTranslationValue(resourceKeys.NewBasic)}} </td>
                        </tr>
                    </table>
                </div>
            </div>
            <div class="accordion-body pb-0 px-1" *ngIf="filterBy == 'admin' || filterBy == 'all' ">
                <table class="w-100 setting-table table-space">
                    <tr class="tablehead w-100">
                        <!-- <th class="ps-3" colspan="2" class=""> {{getLanguageTranslationValue(resourceKeys.AdminSettings)}}</th> -->
                        <th class="ps-3" colspan="2" class="">ADMIN SETTINGS</th>
                    </tr>
                </table>
                <div class="w-100">
                    <table class="w-100 setting-table">
                        <tr class="controlshadow">
                            <td class="w-75 ps-3">
                                <label>{{getLanguageTranslationValue(resourceKeys.HeaderFooterSettings)}}</label>
                            </td>
                            <td class="w-25 left-space"> {{envelopeAdditionalDetails.headerAndFooterDescription}} </td>
                        </tr>

                        <tr class="controlshadow">
                            <td class="w-75 ps-3">
                                <label>{{getLanguageTranslationValue(resourceKeys.DeleteSignedContracts)}}</label>
                            </td>
                            <td class="w-25 left-space" *ngIf="envelopeSettingDetails.isDeleteSignedContracts == true">
                                {{getLanguageTranslationValue(resourceKeys.lang_Y)}} </td>
                            <td class="w-25 left-space"
                                *ngIf="!(envelopeSettingDetails.isDeleteSignedContracts == true)">
                                {{getLanguageTranslationValue(resourceKeys.lang_N)}} </td>
                        </tr>

                        <tr class="controlshadow">
                            <td class="w-75 ps-3">
                                <label>{{getLanguageTranslationValue(resourceKeys.ReferenceCode)}}</label>
                            </td>
                            <td class="w-25 left-space"> {{envelopeAdditionalDetails.referenceCodeSetting}} </td>
                        </tr>

                        <tr class="controlshadow">
                            <td class="w-75 ps-3">
                                <label>{{getLanguageTranslationValue(resourceKeys.FormFieldAlignment)}}</label>
                            </td>
                            <td class="w-25 left-space"
                                *ngIf="envelopeSettingDetails.isFormFieldAlignmentEnabled == true">
                                {{getLanguageTranslationValue(resourceKeys.lang_Y)}} </td>
                            <td class="w-25 left-space"
                                *ngIf="!(envelopeSettingDetails.isFormFieldAlignmentEnabled == true)">
                                {{getLanguageTranslationValue(resourceKeys.lang_N)}} </td>
                        </tr>

                        <tr class="controlshadow">
                            <td class="w-75 ps-3">
                                <label>{{getLanguageTranslationValue(resourceKeys.EnvelopesPostSigningUrl)}}</label>
                            </td>
                            <td class="w-25 left-space" style="text-overflow: ellipsis;"
                                *ngIf="envelopeSettingDetails.envelopePostSigningPageUrl != '' && envelopeSettingDetails.envelopePostSigningPageUrl != null ">
                                {{envelopeSettingDetails.envelopePostSigningPageUrl}} </td>
                            <td class="w-25 left-space"
                                *ngIf="envelopeSettingDetails.envelopePostSigningPageUrl == '' || envelopeSettingDetails.envelopePostSigningPageUrl == null">
                                {{getLanguageTranslationValue(resourceKeys.SetAsDefault)}} </td>
                        </tr>

                        <tr class="controlshadow">
                            <td class="w-75 ps-3">
                                <label>{{getLanguageTranslationValue(resourceKeys.StoreEmailBody)}}</label>
                            </td>
                            <td class="w-25 left-space" *ngIf="envelopeSettingDetails.storeEmailBody == true">
                                {{getLanguageTranslationValue(resourceKeys.lang_Y)}} </td>
                            <td class="w-25 left-space" *ngIf="!(envelopeSettingDetails.storeEmailBody == true)">
                                {{getLanguageTranslationValue(resourceKeys.lang_N)}} </td>
                        </tr>

                        <tr class="controlshadow">
                            <td class="w-75 ps-3">
                                <label>{{getLanguageTranslationValue(resourceKeys.DeleteEmailBody)}}</label>
                            </td>
                            <td class="w-25 left-space"
                                *ngIf="envelopeSettingDetails.allowUserToDeleteEmailBody == true">
                                {{getLanguageTranslationValue(resourceKeys.lang_Y)}} </td>
                            <td class="w-25 left-space"
                                *ngIf="!(envelopeSettingDetails.allowUserToDeleteEmailBody == true)">
                                {{getLanguageTranslationValue(resourceKeys.lang_N)}} </td>
                        </tr>

                        <tr class="controlshadow">
                            <td class="w-75 ps-3">
                                <label>{{getLanguageTranslationValue(resourceKeys.SignatureControlDefaultRequired)}}</label>
                            </td>
                            <td class="w-25 left-space" *ngIf="envelopeSettingDetails.signatureControlRequired == true">
                                {{getLanguageTranslationValue(resourceKeys.lang_Y)}} </td>
                            <td class="w-25 left-space"
                                *ngIf="!(envelopeSettingDetails.signatureControlRequired == true)">
                                {{getLanguageTranslationValue(resourceKeys.lang_N)}} </td>
                        </tr>

                        <tr class="controlshadow">
                            <td class="w-75 ps-3">
                                <label>{{getLanguageTranslationValue(resourceKeys.SendingConfirmationEmail)}}</label>
                            </td>
                            <td class="w-25 left-space"
                                *ngIf="envelopeSettingDetails.receiveSendingEmailConfirmation == true">
                                {{getLanguageTranslationValue(resourceKeys.lang_Y)}} </td>
                            <td class="w-25 left-space"
                                *ngIf="!(envelopeSettingDetails.receiveSendingEmailConfirmation == true)">
                                {{getLanguageTranslationValue(resourceKeys.lang_N)}} </td>
                        </tr>

                        <tr class="controlshadow">
                            <td class="w-75 ps-3">
                                <label>{{getLanguageTranslationValue(resourceKeys.TermsOfService)}}</label>
                            </td>
                            <td class="w-25 left-space" *ngIf="envelopeSettingDetails.isDisclaimerEnabled == true">
                                {{getLanguageTranslationValue(resourceKeys.lang_Y)}} </td>
                            <td class="w-25 left-space" *ngIf="!(envelopeSettingDetails.isDisclaimerEnabled == true)">
                                {{getLanguageTranslationValue(resourceKeys.lang_N)}} </td>
                        </tr>

                        <tr class="controlshadow">
                            <td class="w-75 ps-3">
                                <label>{{getLanguageTranslationValue(resourceKeys.TermsServiceOncertificate)}}</label>
                            </td>
                            <td class="w-25 left-space"
                                *ngIf="envelopeSettingDetails.isDisclaimerInCertificate != null && envelopeSettingDetails.isDisclaimerInCertificate == true">
                                {{getLanguageTranslationValue(resourceKeys.lang_Y)}} </td>
                            <td class="w-25 left-space"
                                *ngIf="!(envelopeSettingDetails.isDisclaimerInCertificate != null && envelopeSettingDetails.isDisclaimerInCertificate == true)">
                                {{getLanguageTranslationValue(resourceKeys.lang_N)}} </td>
                        </tr>

                        <tr class="controlshadow">
                            <td class="w-75 ps-3">
                                <label>{{getLanguageTranslationValue(resourceKeys.DocumentsCombinedInOneEnvelope)}}</label>
                            </td>
                            <td class="w-25 left-space"
                                *ngIf="envelopeSettingDetails.signMultipleDocumentIndependently == true">
                                {{getLanguageTranslationValue(resourceKeys.lang_Y)}} </td>
                            <td class="w-25 left-space"
                                *ngIf="!(envelopeSettingDetails.signMultipleDocumentIndependently == true)">
                                {{getLanguageTranslationValue(resourceKeys.lang_N)}} </td>
                        </tr>

                        <!-- <tr class="controlshadow">
                            <td class="w-75 ps-3">
                                <label>{{getLanguageTranslationValue(resourceKeys.SendInvitationEmailToSigner)}}</label>
                            </td>
                            <td class="w-25 left-space"> {{envelopeAdditionalDetails.sendInvitationEmailToSigner}} </td>
                        </tr> -->

                        <tr class="controlshadow">
                            <td class="w-75 ps-3">
                                <label>{{getLanguageTranslationValue(resourceKeys.SigningCertificatePaperSize)}}</label>
                            </td>
                            <td class="w-25 left-space"> {{envelopeAdditionalDetails.documentPaperSize}} </td>
                        </tr>

                        <tr class="controlshadow">
                            <td class="w-75 ps-3">
                                <label>{{getLanguageTranslationValue(resourceKeys.SignatureRequestReplyAddress)}}</label>
                            </td>
                            <td class="w-25 left-space" *ngIf="envelopeSettingDetails.signReqReplyAddSettingsID == 2">
                                {{getLanguageTranslationValue(resourceKeys.SenderEmail)}} </td>
                            <td class="w-25 left-space" *ngIf="envelopeSettingDetails.signReqReplyAddSettingsID == 3">
                                {{signatureRequestReplyAddress.SenderMailRpostNet}}</td>
                            <td class="w-25 left-space"
                                *ngIf="envelopeSettingDetails.signReqReplyAddSettingsID != 2 && envelopeSettingDetails.signReqReplyAddSettingsID != 3">
                                {{signatureRequestReplyAddress.NoReply}}</td>
                        </tr>

                        <tr class="controlshadow">
                            <td class="w-75 ps-3">
                                <label>{{getLanguageTranslationValue(resourceKeys.StampWatermarkSetting)}}</label>
                            </td>
                            <td class="w-25 left-space" *ngIf="envelopeSettingDetails.stampOnSignerCopySetting == true">
                                {{getLanguageTranslationValue(resourceKeys.lang_Y)}} </td>
                            <td class="w-25 left-space"
                                *ngIf="!(envelopeSettingDetails.stampOnSignerCopySetting == true)">
                                {{getLanguageTranslationValue(resourceKeys.lang_N)}} </td>
                        </tr>

                        <tr class="controlshadow">
                            <td class="w-75 ps-3">
                                <label>{{getLanguageTranslationValue(resourceKeys.SelectDigitalCertificate)}}</label>
                            </td>
                            <td class="w-25 left-space"> {{envelopeAdditionalDetails.digitalCertificateOption}} </td>
                        </tr>

                    </table>
                </div>
            </div>
            <div class="accordion-body pb-0 px-1" *ngIf="filterBy == 'system' || filterBy == 'all' ">
                <table class="w-100 setting-table table-space">
                    <tr class="tablehead w-100">
                        <!-- <th class="ps-3" colspan="2" class=""> {{getLanguageTranslationValue(resourceKeys.SystemSettings)}}</th> -->
                        <th class="ps-3" colspan="2" class="">SYSTEM</th>
                    </tr>
                </table>
                <div class="w-100 table-scroll">
                    <table class="w-100 setting-table">
                        <tr class="controlshadow">
                            <td class="w-75 ps-3">
                                <label>{{getLanguageTranslationValue(resourceKeys.SignatureCapture)}}</label>
                            </td>
                            <td class="w-25 left-space"
                                *ngIf="envelopeSettingDetails.signatureCaptureType == true && envelopeSettingDetails.signatureCaptureHanddrawn == true && envelopeSettingDetails.uploadSignature == true">
                                {{getLanguageTranslationValue(resourceKeys.TypeSignature)}},
                                {{getLanguageTranslationValue(resourceKeys.HandDrawn)}},
                                {{getLanguageTranslationValue(resourceKeys.UploadSignature)}} </td>

                            <td class="w-25 left-space"
                                *ngIf="envelopeSettingDetails.signatureCaptureType == true && envelopeSettingDetails.signatureCaptureHanddrawn == true && !(envelopeSettingDetails.uploadSignature == true)">
                                {{getLanguageTranslationValue(resourceKeys.TypeSignature)}},
                                {{getLanguageTranslationValue(resourceKeys.HandDrawn)}}
                            </td>

                            <td class="w-25 left-space"
                                *ngIf="envelopeSettingDetails.signatureCaptureType == true  && !(envelopeSettingDetails.signatureCaptureHanddrawn == true) &&  envelopeSettingDetails.uploadSignature == true">
                                {{getLanguageTranslationValue(resourceKeys.TypeSignature)}},
                                {{getLanguageTranslationValue(resourceKeys.UploadSignature)}} </td>

                            <td class="w-25 left-space"
                                *ngIf="!(envelopeSettingDetails.signatureCaptureType == true) && envelopeSettingDetails.signatureCaptureHanddrawn == true && envelopeSettingDetails.uploadSignature == true">
                                {{getLanguageTranslationValue(resourceKeys.HandDrawn)}},
                                {{getLanguageTranslationValue(resourceKeys.UploadSignature)}} </td>
                        </tr>

                        <tr class="controlshadow">
                            <td class="w-75 ps-3">
                                <label>{{getLanguageTranslationValue(resourceKeys.TimeZone)}}</label>
                            </td>
                            <td class="w-25 left-space"> {{envelopeSettingDetails.selectedTimeZone}} </td>
                        </tr>
                    </table>
                </div>
            </div>
            <div class="accordion-body pb-0 px-1" *ngIf="filterBy == 'privacy' || filterBy == 'all' ">
                <table class="w-100 setting-table table-space">
                    <tr class="tablehead w-100">
                        <!-- <th class="ps-3" colspan="2" class=""> {{getLanguageTranslationValue(resourceKeys.PrivacySettings)}}</th> -->
                        <th class="ps-3" colspan="2" class="">PRIVACY</th>
                    </tr>
                </table>
                <div class="w-100 table-scroll">
                    <table class="w-100 setting-table">
                        <tr class="controlshadow">
                            <td class="w-75 ps-3">
                                <label>{{getLanguageTranslationValue(resourceKeys.PrivateMode)}}</label>
                            </td>
                            <td class="w-25 left-space"
                                *ngIf="envelopeAdditionalDetails.isPrivateMode != null && envelopeAdditionalDetails.isPrivateMode == true">
                                {{getLanguageTranslationValue(resourceKeys.lang_Y)}} </td>
                            <td class="w-25 left-space"
                                *ngIf="!(envelopeAdditionalDetails.isPrivateMode != null && envelopeAdditionalDetails.isPrivateMode == true)">
                                {{getLanguageTranslationValue(resourceKeys.lang_N)}} </td>
                        </tr>

                        <tr class="controlshadow">
                            <td class="w-75 ps-3"><label
                                    class="d-flex align-items-center">{{getLanguageTranslationValue(resourceKeys.DataMasking)}}<span
                                        class="ms-1 text-danger font-w500">CS</span></label></td>
                            <td class="w-25 left-space"
                                *ngIf="envelopeAdditionalDetails.isDataMasking != null && envelopeAdditionalDetails.isDataMasking == true">
                                {{getLanguageTranslationValue(resourceKeys.lang_Y)}} </td>
                            <td class="w-25 left-space"
                                *ngIf="!(envelopeAdditionalDetails.isDataMasking != null && envelopeAdditionalDetails.isDataMasking == true)">
                                {{getLanguageTranslationValue(resourceKeys.lang_N)}} </td>
                        </tr>

                        <tr class="controlshadow">
                            <td class="w-75 ps-3"><label
                                    class="d-flex align-items-center">{{getLanguageTranslationValue(resourceKeys.DeleteData)}}<span
                                        class="ms-1 text-danger font-w500">CS</span></label></td>
                            <td class="w-25 left-space"
                                *ngIf="envelopeAdditionalDetails.isDataDeletedValue != null && envelopeAdditionalDetails.isDataDeletedValue == true">
                                {{getLanguageTranslationValue(resourceKeys.lang_Y)}}
                                {{envelopeAdditionalDetails.isDataDeleted}}</td>
                            <td class="w-25 left-space"
                                *ngIf="!(envelopeAdditionalDetails.isDataDeletedValue != null && envelopeAdditionalDetails.isDataDeletedValue == true)">
                                {{getLanguageTranslationValue(resourceKeys.lang_N)}} </td>
                        </tr>

                        <tr class="controlshadow">
                            <td colspan="2" class="w-100">
                                <p class="text-danger top10 font-w500 ps-3">CS: <span class="ms-2">
                                        {{getLanguageTranslationValue(resourceKeys.CompanySettings)}}</span> </p>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>

    </div>
    <div class="modal-footer justify-content-end">
        <button class="btn back-btn cancelbtn form-btn" (click)="modalRef.hide()"> Close</button>
        <!-- <button role="button" class="okbtn btn back-btn d-flex align-items-center ms-3" data-bs-dismiss="modal" (click)="Remove()"> Ok</button> -->
    </div>
</div>

<div *ngIf="showLoader" class="loader">
</div>
<div class="modal-content">
	<div class="modal-header">
		<h5 class="modal-title font-w600" id="staticBackdropLabel">
			Dropdown List
		</h5>
		<div class="modalposition" aria-label="Close">
			<span class="material-icons model-closeicon" (click)="onClose()">close</span>
		</div>
	</div>
	<div class="modal-body">
		<div class="mb-3">
			<label for="dropDownItemList" class="form-label">Add List Items</label>
			<div class="d-flex align-items-center justify-content-center">
				<input [(ngModel)]="inputValue" type="text" class="form-control" id="dropDownItemList" #userName
					maxlength="50" placeholder="Enter the text" (keyup.enter)="addOption()" autocomplete="off" />
				<span class="material-icons add-option ms-3" (click)="addOption()">
					add_box
				</span>
			</div>
		</div>

		<div class="mb-1">
			<label *ngIf="dropDownList.length > 0" class="form-label" style="margin-bottom: 0.25rem;">Manage List
				Items</label>
			<div class="optionlist-scroll">
				<table class="w-100">
					<tbody cdkDropList (cdkDropListDropped)="drop($event)" class="example-list">
						<tr class="controlshadow example-box" *ngFor="let list of dropDownList; let listIndex = index"
							cdkDrag>
							<td style="width: 80%;" [ngClass]="{'dropdownfirstRowDisable': list.option == ''}">
								<div class="d-flex align-items-center">
									<span *ngIf="list.option != ''" class="material-icons top3 pointer me-3"
										style="cursor: move;">
										drag_indicator
									</span>
									<ng-container *ngIf="!list.editMode; else editMode">
										<!-- Display mode -->
										<span title="{{ list.option }}" style="
											position: absolute;
											margin-top: 5px;
											width: 63%;
											margin-left: 30px;
											text-overflow: ellipsis;
											display: block;
											white-space: nowrap;
											overflow: hidden;">{{ list.option }}</span>
									</ng-container>
									<ng-template #editMode>
										<!-- Edit mode -->
										<div class="d-flex align-items-center">
											<input [(ngModel)]="list.editValue" style="border: 1px solid #cccccc80;"
												class="form-control form-control-sm" type="text"
												placeholder="Enter text" aria-label="default input example"
												maxlength="40" />
											<span class="material-icons ms-3 pointer"
												(click)="cancelEdit(list)">clear</span>
										</div>
									</ng-template>
								</div>
							</td>
							<td style="width: 20%; text-align: right;" *ngIf="list.option != ''">
								<ng-container *ngIf="!list.editMode; else editModeButtons">
									<!-- Display mode buttons -->
									<i class="fa fa-edit pointer" style="color: #1d4ed8; font-size: 1.275rem;"
										(click)="enableEditMode(list)"></i>
									<span class="material-icons mx-2 top3 pointer" style="color: #bc0001;"
										(click)="deleteOption(list)">delete_outline</span>
								</ng-container>
								<ng-template #editModeButtons>
									<!-- Edit mode buttons -->
									<span class="material-icons top3 pointer" style="color: #1d4ed8;"
										(click)="saveOption(list)">save</span>
									<span class="material-icons mx-2 top3 pointer" style="color: #bc0001;"
										(click)="cancelEdit(list)">delete_outline</span>
								</ng-template>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	</div>
	<div class="modal-footer justify-content-end">
		<button class="btn back-btn cancelbtn form-btn" (click)="onClose()">
			Cancel
		</button>
		<button role="button" class="okbtn btn back-btn form-btn ms-3" (click)="onSave(dropdownListObj)">
			Save
		</button>
	</div>
</div>

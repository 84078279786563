import { Component, OnInit, Input, ViewChild, ElementRef, AfterViewInit, EventEmitter, Directive, HostBinding, HostListener, ComponentRef } from '@angular/core';
import { CommonService } from '../../services/common.service';
import { ControlPropertyService } from 'src/app/services/control.properties.service';
import { CommonConstants, Control } from '../../constants/common-constants';
import { ControlComponent } from '../control/control.component';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services/auth.service';
import { SessionStorageService } from 'src/app/services/session-storage.service';
import { CashedService } from 'src/app/services/cashed.service';
declare var $: any;

const enum Status {
    OFF = 0,
    RESIZE = 1,
    MOVE = 2
}

@Component({
    selector: 'app-resizable-draggable',
    templateUrl: './resizable-draggable.component.html',
    styleUrls: ['./resizable-draggable.component.scss']
})
export class ResizableDraggableComponent implements OnInit, AfterViewInit {
    commonConstants = CommonConstants;
    @Input('width') public width: number = 0;
    @Input('height') public height: number = 0;
    @Input('left') public left: number = 0;
    @Input('top') public top: number = 0;
    @Input('bottom') public bottom: number = 0;
    @ViewChild('box') public box: ElementRef<any>;
    @Input('ctrlObj') public ctrlObj: any = {};
    @Input('minWidth') public minWidth: number = 0;
    @Input('minHeight') public minHeight: number = 0;

    private boxPosition!: { left: number, top: number };
    private containerPos!: { left: number, top: number, right: number, bottom: number };
    public mouse!: { x: number, y: number }
    public status: Status = Status.OFF;
    mouseClick!: { x: number, y: number, left: number, top: number }
    private dynamicComponentRefs: Map<string, ComponentRef<ControlComponent>> = new Map();

    borderWidthDiff: any = 10;
    borderHeightDiff: any = 7;
    containerWidth: any = 0;
    containerHeight: any = 0;

    recipientsObj: any;
    selectedRecipentName: string = '';
    selectedRecipentColor: string = '';
    receipentColorArr: any = [];
    receipentListArr: any = [];
    arrCopyPasteCtrl: any = [];
    arrUndoRedoCtrl: any = [];

    changeText: any;
    isHidden = false;
    showDepencieIcons: boolean = false;
    isActive: boolean = false;
    onCtrlMouseOver: string = '';
    showUpdateSignerEditIcon: boolean = false;
    isEnvelopeEditable: boolean;
    isShowAddIcon: boolean = true;
    controlTitle: string;
    showHideDependenciesIconDisplay: any = '';
    disableDependencyIconsClass: any;

    constructor(
        private commonService: CommonService,
        private controlPropertyService: ControlPropertyService,
        private toastrService: ToastrService,
        private authService: AuthService,
        private sessionStorageService: SessionStorageService,
        private cashedService: CashedService,
    ) {
        this.box = new ElementRef(null);
    }

    ngOnInit() {
        // localStorage.setItem('dependCtrlMoved', 'false');
        this.sessionStorageService.setDependCtrlMoved('false');
        if (this.ctrlObj) {
            this.width = this.ctrlObj.width || 0;
            this.height = this.ctrlObj.height || 0;
            this.left = this.ctrlObj.left || 0;
            this.top = this.ctrlObj.top || 0;
            this.minWidth = this.ctrlObj.minWidth || 0;
            this.minHeight = this.ctrlObj?.minHeight || 0;
            this.recipientsObj = this.ctrlObj.recipientsObj;
            this.isEnvelopeEditable = (this.ctrlObj.isEnvelopeEditable == "true" || this.ctrlObj.isEnvelopeEditable == true) ? true : false;
            this.isShowAddIcon = (this.ctrlObj.isEnvelopeEditable == "true" || this.ctrlObj.isEnvelopeEditable == true) ? true : false;
        }
        // $('.ui-resizable-handle').css('opacity', '0');

        this.cashedService.getControlOriginalTitle(this.ctrlObj.recipientId, this.ctrlObj.id).subscribe((result: any) => {
            this.controlTitle = result;  // Store the result in the component property
        });

        const recipientDetails = this.authService.getRecipientDetails();
        if (recipientDetails) {
            try {
                const parsedRecipientDetails = JSON.parse(recipientDetails);
                this.receipentListArr = parsedRecipientDetails;
            }
            catch (error) {
                console.error('Error parsing recipient details:', error);
            }
        }

        const recipientColorDetails = this.authService.getRecipientColorDetails();
        if (recipientColorDetails) {
            try {
                const parsedRecipientColorDetails = JSON.parse(recipientColorDetails);
                this.receipentColorArr = parsedRecipientColorDetails;
            }
            catch (error) {
                console.error('Error parsing recipient color details:', error);
            }
        }
    }

    ngAfterViewInit() {
        let imageControlEle: any = document.getElementById('imgControl_' + parseInt(this.ctrlObj.pageNo));
        if (imageControlEle) {
            this.containerHeight = (imageControlEle.offsetHeight - this.borderHeightDiff);
            this.containerWidth = (imageControlEle.offsetWidth - this.borderWidthDiff);
        }
        this.loadBox();
        this.loadContainer();
        this.showHideDependenciesIcon();
        this.disableDependencyIconsClass = this.disableDependencyIcon(this.ctrlObj);


    }

    private loadBox() {
        if (this.box && this.box.nativeElement) {
            const { left, top } = this.box?.nativeElement.getBoundingClientRect();
            this.boxPosition = { left, top };
        }
    }

    private loadContainer() {
        const left = this.boxPosition.left - this.left;
        const top = this.boxPosition.top - this.top;
        const right = left + this.containerWidth;
        const bottom = top + this.containerHeight;
        this.containerPos = { left, top, right, bottom };
    }

    setStatus(event: MouseEvent, status: number) {
        if (status === 1) event.stopPropagation();
        else if (status === 2) this.mouseClick = { x: event.clientX, y: event.clientY, left: this.left, top: this.top };
        else this.loadBox();
        this.status = status;
    }

    onMouseMove(event: MouseEvent) {
        if (this.showHideContextMenu() == true) {
            let resizableCurrentControl: any = document.getElementById("resizable_" + this.ctrlObj.id);
            if (resizableCurrentControl) {
                let data_guid = resizableCurrentControl.getAttribute("data-guid");
                let getTextType: any = resizableCurrentControl.getAttribute('data-validation-type');
                if (data_guid.toLowerCase() != Control.Date.toLowerCase() && data_guid.toLowerCase() != Control.Radio.toLowerCase() && data_guid.toLowerCase() != Control.Checkbox.toLowerCase() && (getTextType !== 'SSN' && getTextType !== 'Zip' && getTextType !== 'Date')) {
                    $("#resizable_" + this.ctrlObj.id).resizable();
                    $("#resizable_" + this.ctrlObj.id).resizable('enable');
                    $("#resizable_" + this.ctrlObj.id).resizable({
                        handles: this.getResizeDirection("resizable_" + this.ctrlObj.id),
                        containment: ".imgDocumentId_" + $("#resizable_" + this.ctrlObj.id).attr('pageno'),
                        minHeight: 22,
                        create: (event: any, ui: any) => {
                        },
                        resize: (event: any, ui: any) => {
                            if (this.ctrlObj.controlName === 'Text' || this.ctrlObj.controlName === 'Name' || this.ctrlObj.controlName === 'Title') {
                                getTextType = resizableCurrentControl.getAttribute('data-validation-type');
                                if (getTextType !== 'SSN' && getTextType !== 'Zip' && getTextType !== 'Date') {
                                    this.updateNameOrTextLength();
                                }
                            }
                            if (this.ctrlObj.controlName === 'Label') {
                                this.labelAutoAlignment(this.ctrlObj);
                            }
                        },
                        stop: (event: any, ui: any) => {

                        },
                    });
                }
                else {
                    $("#resizable_" + this.ctrlObj.id).resizable();
                    $("#resizable_" + this.ctrlObj.id).resizable('disable');
                }
                $("#resizable_" + this.ctrlObj.id).draggable({
                    containment: ".imgDocumentId_" + $("#resizable_" + this.ctrlObj.id).attr('pageno')
                });
            }
            this.updateDependenciesToolTips(resizableCurrentControl);
        }
    }

    getResizeDirection(resCtrlId: any) {
        let resizableCurrentControl: any = document.getElementById(resCtrlId);
        let leftAndTopPositions: any = this.controlPropertyService.getElePositions(resizableCurrentControl);
        this.ctrlObj.left = leftAndTopPositions.Left;
        this.ctrlObj.top = leftAndTopPositions.Top;
        this.left = leftAndTopPositions.Left;
        this.top = leftAndTopPositions.Top;
        let handleDirection = 'se';
        let data_guid = resizableCurrentControl.getAttribute("data-guid");
        if (data_guid.toLowerCase() != Control.Date.toLowerCase() &&
            data_guid.toLowerCase() != Control.Radio.toLowerCase() &&
            data_guid.toLowerCase() != Control.Checkbox.toLowerCase()) {
            if (data_guid.toLowerCase() === Control.Name.toLowerCase() ||
                data_guid.toLowerCase() === Control.Hyperlink.toLowerCase() ||
                data_guid.toLowerCase() === Control.DateTimeStamp.toLowerCase() ||
                data_guid.toLowerCase() === Control.Title.toLowerCase() ||
                data_guid.toLowerCase() === Control.Company.toLowerCase() ||
                data_guid.toLowerCase() === Control.Email.toLowerCase() ||
                data_guid.toLowerCase() === Control.DropDown.toLowerCase()) {
                handleDirection = 'e';
            }
            else {
                handleDirection = 'se';
            }
        }
        return handleDirection;
    }

    onMouseOver() {
        if (this.showHideContextMenu() == true) {
            this.showDepencieIcons = true;
        }
        let ctrlEle: any = document.getElementById(this.ctrlObj.id);
        this.onCtrlMouseOver = 'ctrlHover';
        this.showUpdateSignerEditIcon = this.commonService.selectedItems && this.commonService.selectedItems.length > 1 ? true : false;
        if (ctrlEle) {
            ctrlEle.style.zIndex = 999;
            let resizableCurrentControl: any = document.getElementById("resizable_" + this.ctrlObj.id);
            if (resizableCurrentControl) {
                let recId: any = resizableCurrentControl.getAttribute('data-rcptid');
                this.selectedRecipentName = this.controlPropertyService.getRecipientName(recId);
                this.selectedRecipentColor = resizableCurrentControl.getAttribute('data-recp-color');

                this.updateDependenciesToolTips(resizableCurrentControl);
                let isRequired: any = resizableCurrentControl.getAttribute('data-selected');
                let guid: any = resizableCurrentControl.getAttribute('data-guid');
                setTimeout(() => {
                    if (guid.toLowerCase() == Control.Checkbox.toLowerCase()) {
                        let addDependencyIcon: any = document.getElementById("dependency_" + this.ctrlObj.id);
                        if (addDependencyIcon) {
                            if (isRequired == true || isRequired == "true") {
                                addDependencyIcon.classList.remove('text-black');
                                addDependencyIcon.classList.remove('font21');
                                addDependencyIcon.classList.remove('pointer');
                                addDependencyIcon.classList.add('disabledCheckboxCtrl');
                            }
                            else {
                                addDependencyIcon.classList.remove('disabledCheckboxCtrl');
                                addDependencyIcon.classList.remove('disabledCtrl');
                                addDependencyIcon.classList.add('text-black');
                                addDependencyIcon.classList.add('font21');
                                addDependencyIcon.classList.add('pointer');
                            }
                        }
                    }
                }, 30);
            }
        }
    }

    @HostListener('mouseleave', ['$event'])
    onMouseLeave(event: MouseEvent) {
        this.showDepencieIcons = false;
        let ctrlEle: any = document.getElementById(this.ctrlObj.id);
        if (ctrlEle) {
            ctrlEle.style.zIndex = 0;
        }
        this.status = 0;
    }

    @HostListener('click') toActive() {
        this.isActive = !this.isActive;
    }

    onClickDroppedControl(event: any, ctrlObj: any) {
        setTimeout(() => {
            // localStorage.setItem('CurrentClickedDroppedControl', ctrlObj.id);
            this.sessionStorageService.setCurrentClickedDroppedControl(ctrlObj.id);
            let controlTooltipEle: any = document.getElementById('controlInputTooltip');
            let controlEle: any = (<HTMLInputElement>document.getElementById("resizable_" + ctrlObj.id));
            if (controlEle) {
                controlEle.style.background = 'bisque';
                let attr_data_guid: any = controlEle.getAttribute('data-guid');
                let spnSelectEle: any = document.getElementById('spn' + ctrlObj.id);
                if (attr_data_guid.toLowerCase() == Control.DropDown.toLowerCase()) {
                    spnSelectEle.classList.add('mouse-selected');
                }
                spnSelectEle.style.background = '';

                //checking tooltip field
                if (controlTooltipEle) {
                    controlTooltipEle.value = '';
                }
                let isTooltipValue: any = controlEle.getAttribute('data-customtooltip');
                if (isTooltipValue !== '') {
                    if (controlTooltipEle) {
                        controlTooltipEle.value = isTooltipValue;
                    }
                }

                //checking required field
                let isRequired: any = controlEle.getAttribute('data-selected');
                let chkRequiredSelectedEle: any = document.getElementById('chkRequiredSelected');
                if (chkRequiredSelectedEle) {
                    chkRequiredSelectedEle.checked = false;
                    if (isRequired === 'true' || isRequired === true) {
                        chkRequiredSelectedEle.checked = isRequired;
                    }
                }

                //check Name/text control length fields
                let isLength: any = controlEle.getAttribute('maxlengthallowed');
                let nameorTextInputLength: any = (<HTMLInputElement>document.getElementById('nameInputLength'));
                if (ctrlObj.controlName === 'Text') {
                    nameorTextInputLength = (<HTMLInputElement>document.getElementById('textInputLength'));
                }
                if (isLength) {
                    if (nameorTextInputLength) {
                        nameorTextInputLength.value = isLength;
                    }
                }

                // check Name/text control is-fixed-width
                let isFixedWidth: any = controlEle.getAttribute('data-isfixedwidth');
                let isfixedwidthEle: any = (<HTMLInputElement>document.getElementById('isFixedWidth'));
                if (isfixedwidthEle) {
                    isfixedwidthEle.checked = false;
                    if (isFixedWidth === 'true' || isFixedWidth === true) {
                        isfixedwidthEle.checked = isFixedWidth;
                    }
                }

                this.checkIsFixedWidth(ctrlObj, isFixedWidth);
            }
        }, 150);
        this.commonService.callParentShowControlFields(event, ctrlObj);
    }

    getControllingStyle(ctrlname: any) {
        if (ctrlname == "Text" || ctrlname == 'Name' || ctrlname == 'Title') {
            return 'overflowClass';
        }
        else return '';
    }

    onRecipentSelect(event: any, recipient: any, selectedColor: any, ctrlId: any) {
        this.selectedRecipentName = recipient.name;
        this.selectedRecipentColor = selectedColor;
        let recipent: any = { name: recipient.name, id: recipient.id };
        let recipentObj: any = { recipent: recipent, selectedColor: selectedColor };
        this.commonService.callOnMouseOverRecipentChange(recipentObj);
    }

    deleteControl(event: any) {
        if (this.commonService.selectedItems && this.commonService.selectedItems.length) {
            let selecteditemsLength: any = this.sessionStorageService.getSelecteditemsLength();
            if (selecteditemsLength && parseInt(selecteditemsLength) > 1) {
                this.commonService.selectedRightClickOption(event, 'Delete');
                this.onClickOutsideSelectionArea(this.commonService.selectedItems);
                this.sessionStorageService.setSelecteditemsLength('0');
            }

            this.commonService.selectedItems.forEach((ele: any) => {
                let selectedCtrlEle: any = document.getElementById(ele);
                if (selectedCtrlEle) {
                    if (selectedCtrlEle.style.background === 'bisque') {
                        this.onCtrlMouseOver = 'selected';
                    }
                }
            })
            let arrUndoCtrlsCount: any = [];
            // localStorage.setItem('arrUndoCtrlsCount', JSON.stringify(arrUndoCtrlsCount));
            this.sessionStorageService.setArrUndoCtrlsCount(JSON.stringify(arrUndoCtrlsCount));
        }
        let ctrlHoverOrSelected: any = this.onCtrlMouseOver;
        setTimeout(() => {
            this.onClickOutsideSelectionArea(this.commonService.selectedItems);
            this.commonService.callRemoveControlComponent(event, ctrlHoverOrSelected);
        }, 200);
    }

    openDependencyPopup(event: any, ctrlObj: any) {
        // let enableDependenciesFeature: any = localStorage.getItem("enableDependenciesFeature");
        let enableDependenciesFeature: any = this.sessionStorageService.getEnableDependenciesFeature();
        if (enableDependenciesFeature != "false") {
            let resizableCurrentControl: any = document.getElementById("resizable_" + ctrlObj.id);
            if (resizableCurrentControl) {
                let isRequired: any = resizableCurrentControl.getAttribute('data-selected');
                let guid: any = resizableCurrentControl.getAttribute('data-guid');
                if (guid.toLowerCase() == Control.Checkbox.toLowerCase()) {
                    if (isRequired != true && isRequired != "true") {
                        this.sessionStorageService.setCurrentClickedDroppedControl(ctrlObj.id);
                        this.commonService.callOpenDependencyPopup(event);
                    }
                }
                else {
                    this.sessionStorageService.setCurrentClickedDroppedControl(ctrlObj.id);
                    this.commonService.callOpenDependencyPopup(event);
                }
            }
        }
    }

    updateNameOrTextLength() {
        this.commonService.setTextOrNameAreaSetting(this.ctrlObj);
    }

    createDuplicateControl(event: any, ctrlObj: any) {
        let selecteditemsLength: any = this.sessionStorageService.getSelecteditemsLength();
        if (selecteditemsLength && parseInt(selecteditemsLength) > 1) {
            this.commonService.selectedRightClickOption(event, 'Duplicate');
            this.onClickOutsideSelectionArea(this.commonService.selectedItems);
            this.sessionStorageService.setSelecteditemsLength('0');
        }
        else {
            if (ctrlObj.className == "dropdown") {
                let selectDropdownControlField: any = (document.getElementById('spn' + ctrlObj.id)) as HTMLSelectElement;
                if (selectDropdownControlField && selectDropdownControlField.options && selectDropdownControlField.options.length == 0) {
                    this.toastrService.warning('Please add the options for dropdown control.', 'Warning');
                    return false;
                }
            }

            // let arrCopyPasteCtrl: any = localStorage.getItem('ArrCopyPasteCtrl');
            let arrCopyPasteCtrl: any = this.sessionStorageService.getArrCopyPasteCtrl();
            let childCtrlObj: any = ctrlObj;
            // if (arrCopyPasteCtrl) {
            //     this.arrCopyPasteCtrl = JSON.parse(arrCopyPasteCtrl);
            //     if (this.arrCopyPasteCtrl && this.arrCopyPasteCtrl.length > 0) {
            //         if (this.arrCopyPasteCtrl[0].ParentControl.dataGuid == ctrlObj.dataGuid) {
            //             childCtrlObj = this.arrCopyPasteCtrl[0].Control;
            //             this.arrCopyPasteCtrl = [];
            //             this.arrCopyPasteCtrl.push({ ParentControl: ctrlObj, Control: childCtrlObj, Action: 'P' });
            //             // localStorage.setItem('ArrCopyPasteCtrl', JSON.stringify(this.arrCopyPasteCtrl));
            //             this.sessionStorageService.setArrCopyPasteCtrl(JSON.stringify(this.arrCopyPasteCtrl));
            //         }
            //         else {
            //             this.arrUndoRedoCtrl = [];
            //             // localStorage.setItem('ArrUndoRedoCtrl', JSON.stringify(this.arrUndoRedoCtrl));
            //             this.sessionStorageService.setArrUndoRedoCtrl(JSON.stringify(this.arrUndoRedoCtrl));
            //         }
            //     }
            // }

            this.commonService.callOpenCreateDuplicateControl(childCtrlObj);
            return true;
        }


        return;
    }

    disableDependencyIcon(ctrlObj: any) {
        //let enableDependenciesFeature: any = localStorage.getItem("enableDependenciesFeature");
        let enableDependenciesFeature: any = this.sessionStorageService.getEnableDependenciesFeature();
        if (enableDependenciesFeature != "false") {
            let resizableCurrentControl: any = document.getElementById("resizable_" + this.ctrlObj.id);
            if (resizableCurrentControl) {
                let recId: any = resizableCurrentControl.getAttribute('data-rcptid');
                this.selectedRecipentName = this.controlPropertyService.getRecipientName(recId);
                let isRequired: any = resizableCurrentControl.getAttribute('data-selected');
                let guid: any = resizableCurrentControl.getAttribute('data-guid');
                if (guid.toLowerCase() == Control.Checkbox.toLowerCase()) {
                    if (isRequired == true || isRequired == "true") return "fa fa-puzzle-piece top-n2 px-5p disabledCheckboxCtrl";
                    else return "fa fa-puzzle-piece font21 text-black top-n2 px-5p pointer";
                }
                else return "fa fa-puzzle-piece font21 text-black top-n2 px-5p pointer";
            }
            return "fa fa-puzzle-piece top-n2 px-5p disabledCheckboxCtrl";
        }
        else {
            return "fa fa-puzzle-piece top-n2 px-5p disabledCheckboxCtrl";
        }
    }

    showHideDependenciesIcon() {
        let enableDependenciesFeature: any = this.sessionStorageService.getEnableDependenciesFeature();
        if (enableDependenciesFeature == "true") this.showHideDependenciesIconDisplay = "true";
        else this.showHideDependenciesIconDisplay = "false";
    }

    labelAutoAlignment(ctrlObj: any) {
        let currentLabel: any = document.getElementById(ctrlObj.id);
        if (currentLabel) {
            if (currentLabel) {
                let currentLabelSpn: any = document.getElementById('spn' + ctrlObj.id);
                let currentLabelResize: any = document.getElementById('resizable_' + ctrlObj.id);
                let labelSpanHeight = currentLabelSpn.offsetHeight;
                let labelSpanWidth = currentLabelSpn.offsetWidth;
                let labelDivWidth = parseFloat(currentLabelResize.style.width.split('px')[0]);
                let labelDivHeight = parseFloat(currentLabelResize.style.height.split('px')[0]);
                if (labelSpanHeight > labelDivHeight) {
                    setTimeout(() => {
                        currentLabelResize.style.height = (labelSpanHeight + 3) + 'px';
                    }, 200);
                }

                if (labelSpanWidth > labelDivWidth) {
                    setTimeout(() => {
                        currentLabelResize.style.width = (labelSpanWidth + 3) + 'px';
                    }, 200);
                }
            }
        }
    }

    getTransformScale() {
        const element = document.querySelector('.scroll-style');
        if (!element) return { scaleX: 1, scaleY: 1 }; // Default scale values if element not found

        const style = window.getComputedStyle(element);
        const transform = style.transform; //|| style.webkitTransform || style.mozTransform;

        let mat = transform.match(/^matrix\((.+)\)$/);
        let scaleX, scaleY;

        if (mat) {
            const values = mat[1].split(', ').map(parseFloat);
            scaleX = values[0];
            scaleY = values[3];
        } else {
            scaleX = 1; // No scaling applied, default to 1
            scaleY = 1; // No scaling applied, default to 1
        }

        return scaleX;
    }

    checkIsFixedWidth(ctrlObj: any, isFixedWidth: any) {
        let hideFixedwidthCheckbox: any = this.controlPropertyService.getHideFixedwidthCheckbox();
        let spnCurrentSelectedControl: any = (<HTMLInputElement>document.getElementById('resizable_' + ctrlObj.id));
        let dataValidationType: any = spnCurrentSelectedControl.getAttribute('data-validation-type');
        const controlName = ctrlObj.controlName.toLowerCase();
        const inputLengthId = controlName + 'InputLength';
        const charInputLimitId = controlName + 'CharInputLimit';
        let inputLength: any = document.getElementById(inputLengthId);
        let charInputLimit: any = document.getElementById(charInputLimitId);
        if (inputLength) {
            inputLength.removeAttribute('disabled', true);
        }
        if (isFixedWidth === 'false' || isFixedWidth === false) {
            if (charInputLimit) {
                if (controlName === 'text' && dataValidationType && (dataValidationType.toLowerCase() === 'ssn' || dataValidationType.toLowerCase() === 'zip' || dataValidationType.toLowerCase() === 'date')) {
                    this.controlPropertyService.hideCtrlCharLimitInput(charInputLimit);
                } else {
                    this.controlPropertyService.showCtrlCharLimitInput(charInputLimit);
                    if (spnCurrentSelectedControl) {
                        let mxCharVal = spnCurrentSelectedControl.getAttribute('data-maxcharlimit');
                        if (mxCharVal) {
                            if (controlName === 'text' && dataValidationType && (dataValidationType.toLowerCase() === 'email')) {
                                if (mxCharVal <= 100) {
                                    charInputLimit.value = mxCharVal;
                                } else {
                                    charInputLimit.value = '100';
                                }
                            } else {
                                charInputLimit.value = mxCharVal;
                            }
                        }
                    }
                    let isFixedWidthCont: any = document.getElementById('isFixedWidthTextContainer');
                    if (isFixedWidthCont) {
                        isFixedWidthCont.style.display = 'block';
                    }
                }
            }
            if (controlName === 'text' && dataValidationType && (dataValidationType.toLowerCase() === 'ssn' || dataValidationType.toLowerCase() === 'zip' || dataValidationType.toLowerCase() === 'date')) {
                if (inputLength) {
                    this.controlPropertyService.showCtrlLengthInput(inputLength);
                    setTimeout(() => {
                        inputLength.setAttribute('disabled', true);
                    }, 0);
                    let isFixedWidthCont: any = document.getElementById('isFixedWidthTextContainer');
                    if (isFixedWidthCont) {
                        isFixedWidthCont.style.display = 'none';
                    }
                }
            } else {
                if (inputLength) {
                    this.controlPropertyService.hideCtrlLengthInput(inputLength);
                }
            }
        } else {
            if (inputLength) {
                if (controlName === 'text' && dataValidationType && (dataValidationType.toLowerCase() === 'ssn' || dataValidationType.toLowerCase() === 'zip' || dataValidationType.toLowerCase() === 'date' || dataValidationType.toLowerCase() === 'email')) {
                    setTimeout(() => {
                        inputLength.setAttribute('disabled', true);
                    }, 0);
                }
                this.controlPropertyService.showCtrlLengthInput(inputLength);
                if (controlName === 'title') {
                    let maxlengthallowedValue: any = spnCurrentSelectedControl.getAttribute('maxlengthallowed');
                    if (maxlengthallowedValue) {
                        inputLength.value = maxlengthallowedValue;
                    } else {
                        inputLength.value = '20';
                    }
                }
            }
            if (charInputLimit) {
                this.controlPropertyService.hideCtrlCharLimitInput(charInputLimit);
            }
            if (hideFixedwidthCheckbox === true) {
                let isFixedWidthCont: any = document.getElementById('isFixedWidthTextContainer');
                if (isFixedWidthCont) {
                    isFixedWidthCont.style.display = 'none';
                }
            } else {
                let isFixedWidthCont: any = document.getElementById('isFixedWidthTextContainer');
                if (isFixedWidthCont) {
                    isFixedWidthCont.style.display = 'block';
                }
            }
        }
    }

    showHideContextMenu() {
        let isEnvelopeEditable: any = this.sessionStorageService.getIsEnvelopeEditable()
        if (isEnvelopeEditable == "true") return true;
        else return false;
    }

    //hover the dropped control, adjusting to left, right and top positions for tooltips elements
    updateDependenciesToolTips(resizableCurrentControl: any) {
        let ctrlPositions: any = this.controlPropertyService.getElePositions(resizableCurrentControl);
        let containerWidthVal: any = this.containerWidth / 2;
        let containerHeightVal: any = 50;
        if (ctrlPositions) {
            if (containerWidthVal > ctrlPositions.Left) {
                //params are control element, left and right
                this.updateLeftAndRightValues(resizableCurrentControl, '-3px', 'auto');
            }
            else if (containerWidthVal < ctrlPositions.Left) {
                this.updateLeftAndRightValues(resizableCurrentControl, 'auto', '-3px');
            }

            if (containerHeightVal > ctrlPositions.Top) {
                if (resizableCurrentControl && resizableCurrentControl.childNodes[0] && resizableCurrentControl.childNodes[0].style) {
                    let height: any = resizableCurrentControl.style.height.split('px')[0];
                    resizableCurrentControl.childNodes[0].style.top = parseInt(height) + 2 + 'px';
                }
            }
            else if (containerHeightVal < ctrlPositions.Top) {
                if (resizableCurrentControl && resizableCurrentControl.childNodes[0] && resizableCurrentControl.childNodes[0].style) {
                    resizableCurrentControl.childNodes[0].style.top = '-40px';
                }
            }
        }
    }

    updateLeftAndRightValues(resizableCurrentControl: any, left: any, right: any) {
        if (resizableCurrentControl && resizableCurrentControl.childNodes[0] && resizableCurrentControl.childNodes[0].style) {
            resizableCurrentControl.childNodes[0].style.left = left;
            resizableCurrentControl.childNodes[0].style.right = right;
        }
    }

    onClickOutsideSelectionArea(list: any) {
        if (list && list.length) {
            list.forEach((ele: any) => {
                let selectedCtrlEle: any = document.getElementById(ele);
                if (selectedCtrlEle) {
                    selectedCtrlEle.style.background = '#ffffff';
                }
            })
        }
    }

    updateSignerMultipleControls(event: any) {
        if (this.commonService.selectedItems && this.commonService.selectedItems.length) {
            this.commonService.callUpdateSignerMultipleControls()
        }
    }
}

import { Injectable } from '@angular/core';
import { CommonConstants } from '../constants/common-constants';
import { SessionStorageService } from './session-storage.service';

// Define an interface for the control text object
interface ControlTextObject {
	saveAsDefault: any,
	required: any,
	fontFamily: string;
	fontWeight: any;
	textDecoration: string;
	fontStyle: string;
	fontSize: string;
	color: string;
	textAlign: string;
	lineHeight: string;
	validationType: string
	adlnName: string,
	adlnOption: string,
	preSelected: any,
	checkboxGroup: any,
	checkboxGroupName: any,
	checkboxName: any,
	dateFormat: string,
	size: string,
	fontTypeMeasurement: string,
	firstLineBlank: any,
	labelControlValue: string
}

// Define an interface for the dictionary containing control text objects
interface Dictionary {
	[key: string]: ControlTextObject;
}

@Injectable({
	providedIn: 'root'
})
export class DefaultControlStyleService {

	commonConstants = CommonConstants;
	latestSaveAsDefaultData: any[] = [];
	controlSaveAsObj: any = {};

	// Initialize dictionaries with default text properties for each control
	dict: Dictionary = {
		'sign': {
			saveAsDefault: undefined,
			required: true,
			fontFamily: 'Courier',
			fontWeight: 'normal',
			textDecoration: 'none',
			fontStyle: 'normal',
			fontSize: '12px',
			color: '#000',
			textAlign: 'left',
			lineHeight: '12px',
			validationType: '',
			adlnName: '',
			adlnOption: '',
			preSelected: false,
			checkboxGroup: false,
			checkboxGroupName: '',
			checkboxName: '',
			dateFormat: '',
			size: '',
			fontTypeMeasurement: 'px',
			firstLineBlank: false,
			labelControlValue: ''
		},
		'initials': {
			saveAsDefault: undefined,
			required: true,
			fontFamily: 'Courier',
			fontWeight: 'normal',
			textDecoration: 'none',
			fontStyle: 'normal',
			fontSize: '12px',
			color: '#000',
			textAlign: 'left',
			lineHeight: '12px',
			validationType: '',
			adlnName: '',
			adlnOption: '',
			preSelected: false,
			checkboxGroup: false,
			checkboxGroupName: '',
			checkboxName: '',
			dateFormat: '',
			size: '',
			fontTypeMeasurement: 'px',
			firstLineBlank: false,
			labelControlValue: ''
		},
		'datetimestamp': {
			saveAsDefault: undefined,
			required: false,
			fontFamily: 'Courier',
			fontWeight: 'normal',
			textDecoration: 'none',
			fontStyle: 'normal',
			fontSize: '12px',
			color: '#000',
			textAlign: 'left',
			lineHeight: '12px',
			validationType: '',
			adlnName: '',
			adlnOption: '',
			preSelected: false,
			checkboxGroup: false,
			checkboxGroupName: '',
			checkboxName: '',
			dateFormat: '',
			size: '',
			fontTypeMeasurement: 'px',
			firstLineBlank: false,
			labelControlValue: ''
		},
		'name': {
			saveAsDefault: undefined,
			required: true,
			fontFamily: 'Courier',
			fontWeight: 'normal',
			textDecoration: 'none',
			fontStyle: 'normal',
			fontSize: '12px',
			color: '#000',
			textAlign: 'left',
			lineHeight: '12px',
			validationType: '',
			adlnName: '',
			adlnOption: '',
			preSelected: false,
			checkboxGroup: false,
			checkboxGroupName: '',
			checkboxName: '',
			dateFormat: '',
			size: '',
			fontTypeMeasurement: 'px',
			firstLineBlank: false,
			labelControlValue: ''
		},
		'title': {
			saveAsDefault: undefined,
			required: true,
			fontFamily: 'Courier',
			fontWeight: 'normal',
			textDecoration: 'none',
			fontStyle: 'normal',
			fontSize: '12px',
			color: '#000',
			textAlign: 'left',
			lineHeight: '12px',
			validationType: '',
			adlnName: '',
			adlnOption: '',
			preSelected: false,
			checkboxGroup: false,
			checkboxGroupName: '',
			checkboxName: '',
			dateFormat: '',
			size: '',
			fontTypeMeasurement: 'px',
			firstLineBlank: false,
			labelControlValue: ''
		},
		'company': {
			saveAsDefault: undefined,
			required: true,
			fontFamily: 'Courier',
			fontWeight: 'normal',
			textDecoration: 'none',
			fontStyle: 'normal',
			fontSize: '12px',
			color: '#000',
			textAlign: 'left',
			lineHeight: '12px',
			validationType: '',
			adlnName: '',
			adlnOption: '',
			preSelected: false,
			checkboxGroup: false,
			checkboxGroupName: '',
			checkboxName: '',
			dateFormat: '',
			size: '',
			fontTypeMeasurement: 'px',
			firstLineBlank: false,
			labelControlValue: ''
		},
		'email': {
			saveAsDefault: undefined,
			required: true,
			fontFamily: 'Courier',
			fontWeight: 'normal',
			textDecoration: 'none',
			fontStyle: 'normal',
			fontSize: '12px',
			color: '#000',
			textAlign: 'left',
			lineHeight: '12px',
			validationType: '',
			adlnName: '',
			adlnOption: '',
			preSelected: false,
			checkboxGroup: false,
			checkboxGroupName: '',
			checkboxName: '',
			dateFormat: '',
			size: '',
			fontTypeMeasurement: 'px',
			firstLineBlank: false,
			labelControlValue: ''
		},
		'date': {
			saveAsDefault: undefined,
			required: true,
			fontFamily: 'Courier',
			fontWeight: 'normal',
			textDecoration: 'none',
			fontStyle: 'normal',
			fontSize: '12px',
			color: '#000',
			textAlign: 'left',
			lineHeight: '',
			validationType: '',
			adlnName: '',
			adlnOption: '',
			preSelected: false,
			checkboxGroup: false,
			checkboxGroupName: '',
			checkboxName: '',
			dateFormat: '',
			size: '',
			fontTypeMeasurement: 'px',
			firstLineBlank: false,
			labelControlValue: ''
		},
		'label': {
			saveAsDefault: undefined,
			required: true,
			fontFamily: 'Courier',
			fontWeight: 'normal',
			textDecoration: 'none',
			fontStyle: 'normal',
			fontSize: '12px',
			color: '#000',
			textAlign: 'left',
			lineHeight: '12px',
			validationType: '',
			adlnName: '',
			adlnOption: '',
			preSelected: false,
			checkboxGroup: false,
			checkboxGroupName: '',
			checkboxName: '',
			dateFormat: '',
			size: '',
			fontTypeMeasurement: 'px',
			firstLineBlank: false,
			labelControlValue: ''
		},
		'hyperlink': {
			saveAsDefault: undefined,
			required: true,
			fontFamily: 'Courier',
			fontWeight: 'normal',
			textDecoration: 'none',
			fontStyle: 'normal',
			fontSize: '12px',
			color: '#000',
			textAlign: 'left',
			lineHeight: '12px',
			validationType: '',
			adlnName: '',
			adlnOption: '',
			preSelected: false,
			checkboxGroup: false,
			checkboxGroupName: '',
			checkboxName: '',
			dateFormat: '',
			size: '',
			fontTypeMeasurement: 'px',
			firstLineBlank: false,
			labelControlValue: ''
		},
		'text': {
			saveAsDefault: undefined,
			required: true,
			fontFamily: 'Courier',
			fontWeight: 'normal',
			textDecoration: 'none',
			fontStyle: 'normal',
			fontSize: '12px',
			color: '#000',
			textAlign: 'left',
			lineHeight: '12px',
			validationType: 'text',
			adlnName: '',
			adlnOption: '',
			preSelected: false,
			checkboxGroup: false,
			checkboxGroupName: '',
			checkboxName: '',
			dateFormat: '',
			size: '',
			fontTypeMeasurement: 'px',
			firstLineBlank: false,
			labelControlValue: ''
		},
		'dropdown': {
			saveAsDefault: true,
			required: false,
			fontFamily: 'Courier',
			fontWeight: 'normal',
			textDecoration: 'none',
			fontStyle: 'normal',
			fontSize: '12px',
			color: '#000',
			textAlign: 'left',
			lineHeight: '12px',
			validationType: '',
			adlnName: '',
			adlnOption: '',
			preSelected: false,
			checkboxGroup: false,
			checkboxGroupName: '',
			checkboxName: '',
			dateFormat: '',
			size: '',
			fontTypeMeasurement: 'px',
			firstLineBlank: false,
			labelControlValue: ''
		},
		'checkbox': {
			saveAsDefault: true,
			required: false,
			fontFamily: 'Courier',
			fontWeight: 'normal',
			textDecoration: 'none',
			fontStyle: 'normal',
			fontSize: '12px',
			color: '#000',
			textAlign: 'left',
			lineHeight: '12px',
			validationType: '',
			adlnName: '',
			adlnOption: '',
			preSelected: false,
			checkboxGroup: false,
			checkboxGroupName: '',
			checkboxName: '',
			dateFormat: '',
			size: 'standard',
			fontTypeMeasurement: 'px',
			firstLineBlank: false,
			labelControlValue: ''
		},
		'radio': {
			saveAsDefault: true,
			required: false,
			fontFamily: 'Courier',
			fontWeight: 'normal',
			textDecoration: 'none',
			fontStyle: 'normal',
			fontSize: '12px',
			color: '#000',
			textAlign: 'left',
			lineHeight: '12px',
			validationType: '',
			adlnName: '',
			adlnOption: '',
			preSelected: false,
			checkboxGroup: false,
			checkboxGroupName: '',
			checkboxName: '',
			dateFormat: '',
			size: 'standard',
			fontTypeMeasurement: 'px',
			firstLineBlank: false,
			labelControlValue: ''
		}
	};

	constructor(private sessionStorageService: SessionStorageService,) { }

	// Function to update a specific dictionary based on its key
	updateDictionary(key: string, data: ControlTextObject): void {
		if (key.toLowerCase() === 'signature') {
			key = 'sign';
		}
		if (key.toLowerCase() === 'datetime' || key.toLowerCase() === 'datetimestamp') {
			key = 'datetimestamp';
		}
		if (key.toLowerCase() === 'newinitials') {
			key = 'initials';
		}

		if (key in this.dict) {
			this.dict[key] = data;
			// Here you can add code to save the updated data
		} else {
			console.log("Invalid key.");
		}
	}

	// Function to get the dictionary based on a key
	getDictionaryByKey(key: string): ControlTextObject {
		return this.dict[key]; //|| this.getDefaultControlTextObject();
	}

	getCtrlBasedSaveAsDefaultValue(key: string) {
		if (key.toLowerCase() === 'signature') {
			key = 'sign';
		}
		if (key.toLowerCase() === 'datetime' || key.toLowerCase() === 'datetimestamp') {
			key = 'datetimestamp';
		}
		if (key.toLowerCase() === 'newinitials') {
			key = 'initials';
		}
		return this.dict[key].saveAsDefault;
	}

	getDefaultControlTextObject() {
		return {
			saveAsDefault: true,
			required: true,
			fontFamily: 'Courier',
			fontWeight: 'normal',
			textDecoration: 'none',
			fontStyle: 'normal',
			fontSize: '12px',
			color: '#000',
			textAlign: 'left',
			lineHeight: '12px',
			validationType: '',
			adlnName: '',
			adlnOption: '',
			preSelected: false,
			checkboxGroup: false,
			checkboxGroupName: '',
			checkboxName: '',
			dateFormat: '',
			size: 'standard',
			fontTypeMeasurement: 'px',
			firstLineBlank: false,
			labelControlValue: ''
		};
	}

	getRequiredFields(key: string) {
		if (key.toLowerCase() === 'signature') {
			key = 'sign';
		}
		if (key.toLowerCase() === 'datetime' || key.toLowerCase() === 'datetimestamp') {
			key = 'datetimestamp';
		}
		if (key.toLowerCase() === 'newinitials') {
			key = 'initials';
		}
		return this.dict[key].required;
	}


	updateSaveAsDefaultPayload(currentSelectedControl: any, ctrlName: any, spnctrlEle: any, fontFamilyWithoutQuotes: any, isRequiredChecked: any, typography: any) {
		let resizableCtrlEle: any = document.getElementById('resizable_' + currentSelectedControl);
		let controlEleName: any = '';
		if (ctrlName.toLowerCase() === 'signature') { ctrlName = 'sign'; controlEleName = "Sign"; }
		if (ctrlName.toLowerCase() === 'datetime' || ctrlName.toLowerCase() === 'datetimestamp') { ctrlName = 'datetimestamp'; controlEleName = "DateTimeStamp"; }
		if (ctrlName.toLowerCase() === 'newinitials') { ctrlName = 'initials'; controlEleName = "newInitials"; }
		if (ctrlName.toLowerCase() === 'hyperlink') { controlEleName = "Hyperlink"; }
		if (ctrlName.toLowerCase() === 'name') { controlEleName = "Name"; }
		if (ctrlName.toLowerCase() === 'title') { controlEleName = "Title"; }
		if (ctrlName.toLowerCase() === 'company') { controlEleName = "Company"; }
		if (ctrlName.toLowerCase() === 'email') { controlEleName = "Email"; }
		if (ctrlName.toLowerCase() === 'text') { controlEleName = "Text"; }
		if (ctrlName.toLowerCase() === 'radio') { controlEleName = "Radio"; }
		if (ctrlName.toLowerCase() === 'checkbox') { controlEleName = "Checkbox"; }
		if (ctrlName.toLowerCase() === 'dropdown') { controlEleName = "Dropdown"; }
		if (ctrlName.toLowerCase() === 'date') { controlEleName = "Date"; }
		if (ctrlName.toLowerCase() === 'label') { controlEleName = "Label"; }

		let hexColor: any = this.rgbToHex(spnctrlEle.style.color);
		if (ctrlName.toLowerCase() === 'sign' || ctrlName.toLowerCase() === 'initials') {
			this.controlSaveAsObj = {
				'Control': controlEleName,
				'Values': {
					'Color': hexColor,
					'Required': isRequiredChecked
				}
			}
		}

		if (ctrlName.toLowerCase() === 'datetimestamp' || ctrlName.toLowerCase() === 'hyperlink') {
			this.controlSaveAsObj = {
				'Control': controlEleName,
				'Values': {
					'Color': hexColor,
					'Bold': spnctrlEle.style.fontWeight ? (spnctrlEle.style.fontWeight === 'bold' || spnctrlEle.style.fontWeight === '600') ? true : false : false,
					'Underline': spnctrlEle.style.textDecoration ? (spnctrlEle.style.textDecoration === 'underline') ? true : false : false,
					'Italics': spnctrlEle.style.fontStyle ? (spnctrlEle.style.fontStyle === 'italic') ? true : false : false,
					'FontType': fontFamilyWithoutQuotes != null && fontFamilyWithoutQuotes != 'null' && fontFamilyWithoutQuotes != undefined ? fontFamilyWithoutQuotes : '',
					'FontSize': spnctrlEle.style.fontSize ? spnctrlEle.style.fontSize.split('px')[0] : 12,
					'FontTypeMeasurement': typography && typography != '' && typography != null ? typography : 'px',
					'TextAlign': spnctrlEle.style.textAlign ? spnctrlEle.style.textAlign : 'left'

				}
			}
		}

		if (ctrlName.toLowerCase() === 'name' || ctrlName.toLowerCase() === 'title' || ctrlName.toLowerCase() === 'company' || ctrlName.toLowerCase() === 'email') {
			this.controlSaveAsObj = {
				'Control': controlEleName,
				'Values': {
					'Color': hexColor,
					'Required': isRequiredChecked,
					'Bold': spnctrlEle.style.fontWeight ? (spnctrlEle.style.fontWeight === 'bold' || spnctrlEle.style.fontWeight === '600') ? true : false : false,
					'Underline': spnctrlEle.style.textDecoration ? (spnctrlEle.style.textDecoration === 'underline') ? true : false : false,
					'Italics': spnctrlEle.style.fontStyle ? (spnctrlEle.style.fontStyle === 'italic') ? true : false : false,
					'FontType': fontFamilyWithoutQuotes != null && fontFamilyWithoutQuotes != 'null' && fontFamilyWithoutQuotes != undefined ? fontFamilyWithoutQuotes : '',
					'FontSize': spnctrlEle.style.fontSize ? spnctrlEle.style.fontSize.split('px')[0] : 12,
					'FontTypeMeasurement': typography && typography != '' && typography != null ? typography : 'px',
					'TextAlign': spnctrlEle.style.textAlign ? spnctrlEle.style.textAlign : 'left'

				}
			}
		}

		if (ctrlName.toLowerCase() === 'label') {
			this.controlSaveAsObj = {
				'Control': controlEleName,
				'Values': {
					'Color': hexColor,
					'Bold': spnctrlEle.style.fontWeight ? (spnctrlEle.style.fontWeight === 'bold' || spnctrlEle.style.fontWeight === '600') ? true : false : false,
					'Underline': spnctrlEle.style.textDecoration ? (spnctrlEle.style.textDecoration === 'underline') ? true : false : false,
					'Italics': spnctrlEle.style.fontStyle ? (spnctrlEle.style.fontStyle === 'italic') ? true : false : false,
					'FontType': fontFamilyWithoutQuotes != null && fontFamilyWithoutQuotes != 'null' && fontFamilyWithoutQuotes != undefined ? fontFamilyWithoutQuotes : '',
					'FontSize': spnctrlEle.style.fontSize ? spnctrlEle.style.fontSize.split('px')[0] : 12,
					'FontTypeMeasurement': typography && typography != '' && typography != null ? typography : 'px',
					'TextAlign': spnctrlEle.style.textAlign ? spnctrlEle.style.textAlign : 'left',
					'LabelControlValue': spnctrlEle.innerText ? spnctrlEle.innerText : ''
				}
			}
		}

		if (ctrlName.toLowerCase() === 'date') {
			let prefillDateCheckbox: any = document.getElementById('prefillDateCheckbox');
			if (resizableCtrlEle && prefillDateCheckbox) {
				if (resizableCtrlEle.getAttribute('data-defaultdate') == 'true') {
					prefillDateCheckbox.checked = true;
				} else {
					prefillDateCheckbox.checked = false;
				}
			}
			this.controlSaveAsObj = {
				'Control': controlEleName,
				'Values': {
					'Color': hexColor,
					'Required': isRequiredChecked,
					'Bold': spnctrlEle.style.fontWeight ? (spnctrlEle.style.fontWeight === 'bold' || spnctrlEle.style.fontWeight === '600') ? true : false : false,
					'Underline': spnctrlEle.style.textDecoration ? (spnctrlEle.style.textDecoration === 'underline') ? true : false : false,
					'Italics': spnctrlEle.style.fontStyle ? (spnctrlEle.style.fontStyle === 'italic') ? true : false : false,
					'FontType': fontFamilyWithoutQuotes != null && fontFamilyWithoutQuotes != 'null' && fontFamilyWithoutQuotes != undefined ? fontFamilyWithoutQuotes : '',
					'FontSize': spnctrlEle.style.fontSize ? spnctrlEle.style.fontSize.split('px')[0] : 12,
					'FontTypeMeasurement': typography && typography != '' && typography != null ? typography : 'px',
					'TextAlign': spnctrlEle.style.textAlign ? spnctrlEle.style.textAlign : 'left',
					'IsDefaultRequired': prefillDateCheckbox ? prefillDateCheckbox.checked : false,
					'DateFormat': spnctrlEle.innerText ? spnctrlEle.innerText : ''
				}
			}
		}

		if (ctrlName.toLowerCase() === 'dropdown') {
			let drpFirstLineBlank: any = document.getElementById('drpFirstLineBlank');
			if (resizableCtrlEle && drpFirstLineBlank) {
				if (resizableCtrlEle.getAttribute('data-firstlineblank') == 'true') {
					drpFirstLineBlank.checked = true;
				} else {
					drpFirstLineBlank.checked = false;
				}
			}

			this.controlSaveAsObj = {
				'Control': controlEleName,
				'Values': {
					'Color': hexColor,
					'Required': isRequiredChecked,
					'PreSelected': drpFirstLineBlank ? drpFirstLineBlank.checked : false
				}
			}
		}

		if (ctrlName.toLowerCase() === 'checkbox') {
			let chkPreSelected: any = document.getElementById('chkPreSelected');
			if (resizableCtrlEle && chkPreSelected) {
				if (resizableCtrlEle.getAttribute('data-selected1') == 'true') {
					chkPreSelected.checked = true;
				} else {
					chkPreSelected.checked = false;
				}
			}

			let checkboxgroup: any = document.getElementById('checkboxgroup');
			if (resizableCtrlEle && checkboxgroup) {
				if (resizableCtrlEle.getAttribute('data-isgroupchecked') == 'true') {
					checkboxgroup.checked = true;
				} else {
					checkboxgroup.checked = false;
				}
			}

			let dataSize: any = resizableCtrlEle.getAttribute('data-size');

			this.controlSaveAsObj = {
				'Control': controlEleName,
				'Values': {
					'Required': isRequiredChecked,
					'PreSelected': chkPreSelected ? chkPreSelected.checked : false,
					'CheckboxGroup': checkboxgroup ? checkboxgroup.checked : false,
					'Size': dataSize ? dataSize : 'standard'
				}
			}
		}

		if (ctrlName.toLowerCase() === 'radio') {
			let chkRadioRequiredSelected: any = document.getElementById('chkRadioRequiredSelected');
			if (resizableCtrlEle && chkRadioRequiredSelected) {
				if (resizableCtrlEle.getAttribute('data-selected') == 'true') {
					chkRadioRequiredSelected.checked = true;
				} else {
					chkRadioRequiredSelected.checked = false;
				}
			}

			let chkPreSelected: any = document.getElementById('chkPreSelected');
			if (resizableCtrlEle && chkPreSelected) {
				if (resizableCtrlEle.getAttribute('data-selected1') == 'true') {
					chkPreSelected.checked = true;
				} else {
					chkPreSelected.checked = false;
				}
			}

			let dataSize: any = resizableCtrlEle.getAttribute('data-size');

			this.controlSaveAsObj = {
				'Control': controlEleName,
				'Values': {
					'Required': chkRadioRequiredSelected ? chkRadioRequiredSelected.checked : false,
					'PreSelected': chkPreSelected ? chkPreSelected.checked : false,
					'Size': dataSize ? dataSize : 'standard'
				}
			}
		}

		if (ctrlName.toLowerCase() === 'text') {
			let dataValidationType: any;
			let dataAdditionalValidationName: string = '';
			let dataAdditionalValidationOption: string = '';
			let validationOption: any = '';

			dataValidationType = resizableCtrlEle.getAttribute('data-validation-type');
			dataAdditionalValidationName = resizableCtrlEle.getAttribute('data-additional-validation-name');
			dataAdditionalValidationOption = resizableCtrlEle.getAttribute('data-additional-validation-option');

			if (dataValidationType && dataValidationType.toLowerCase() === 'zip' && dataAdditionalValidationOption) {
				validationOption = this.commonConstants.ValidationsZipOptions[parseInt(dataAdditionalValidationOption) - 1].viewname;
			}
			if (dataValidationType && dataValidationType.toLowerCase() === 'numeric' && dataAdditionalValidationOption) {
				validationOption = this.commonConstants.AdditionalValidationType[parseInt(dataAdditionalValidationOption) - 1].viewValue;
			}
			if (dataValidationType && dataValidationType.toLowerCase() === 'date' && dataAdditionalValidationOption) {
				validationOption = this.commonConstants.ValidationsDateOptions[parseInt(dataAdditionalValidationOption) - 1].label;
			}

			let dataValidationTypeValue: any = this.commonConstants.ValidationsText[0].value;
			if (this.commonConstants.ValidationsText) {
				this.commonConstants.ValidationsText.forEach((ele: any) => {
					if (ele.label.toLowerCase() === dataValidationType.toLowerCase()) {
						dataValidationTypeValue = ele.value;
					}
				})
			}


			this.controlSaveAsObj = {
				'Control': controlEleName,
				'Values': {
					'Color': hexColor,
					'Required': isRequiredChecked,
					'Bold': spnctrlEle.style.fontWeight ? (spnctrlEle.style.fontWeight === 'bold' || spnctrlEle.style.fontWeight === '600') ? true : false : false,
					'Underline': spnctrlEle.style.textDecoration ? (spnctrlEle.style.textDecoration === 'underline') ? true : false : false,
					'Italics': spnctrlEle.style.fontStyle ? (spnctrlEle.style.fontStyle === 'italic') ? true : false : false,
					'FontType': fontFamilyWithoutQuotes != null && fontFamilyWithoutQuotes != 'null' && fontFamilyWithoutQuotes != undefined ? fontFamilyWithoutQuotes : '',
					'FontSize': spnctrlEle.style.fontSize ? spnctrlEle.style.fontSize.split('px')[0] : 12,
					'FontTypeMeasurement': typography && typography != '' && typography != null ? typography : 'px',
					'TextAlign': spnctrlEle.style.textAlign ? spnctrlEle.style.textAlign : 'left',
					'ValidationType': dataValidationTypeValue ? dataValidationTypeValue : '',
					'AdlnName': dataAdditionalValidationName ? dataAdditionalValidationName : '',
					'AdlnOption': validationOption ? validationOption : ''
				}
			}
		}

		this.latestSaveAsDefaultData = this.sessionStorageService.getDefaultControlStyles();
		if (this.latestSaveAsDefaultData == undefined || this.latestSaveAsDefaultData == null) this.latestSaveAsDefaultData = [];
		else {
			// this.latestSaveAsDefaultData = this.latestSaveAsDefaultData.filter((f) => f.Control != ctrlName.toLowerCase());
			this.latestSaveAsDefaultData.push(this.controlSaveAsObj);
		}

		let newData: any = [];
		this.latestSaveAsDefaultData.forEach((item: { Control: any; Values: any; }) => {
			const existingItemIndex = newData.findIndex((existingItem: { Control: any; }) => existingItem.Control === item.Control);
			if (existingItemIndex !== -1) {
				// If item already exists, update its values
				newData[existingItemIndex].Values = item.Values;
			} else {
				// If item doesn't exist, push it
				newData.push(item);
			}
		});
		this.latestSaveAsDefaultData = newData;
		this.sessionStorageService.setDefaultControlStyles(this.latestSaveAsDefaultData);
		return newData;
	}

	removeLowercaseDefaultStyles(parseDefaultControlProperty: any) {
		let updatedDefaultControlProperty: any = [];
		if(parseDefaultControlProperty){
			Array.from(parseDefaultControlProperty).forEach((ele: any, index: any) => {
				if (ele.Control == 'sign' || ele.Control == 'datetimestamp' || ele.Control == 'initials' || ele.Control == 'newinitials' ||
					ele.Control == 'hyperlink' || ele.Control == 'name' || ele.Control == 'title' ||
					ele.Control == 'company' || ele.Control == 'email' || ele.Control == 'text' ||
					ele.Control == 'radio' || ele.Control == 'checkbox' || ele.Control == 'dropdown' ||
					ele.Control == 'date' || ele.Control == 'label' || ele.Control == 'stamp') { }
				else {
					updatedDefaultControlProperty.push(ele);
				}
			});
		}
		return updatedDefaultControlProperty;
	}

	getSaveAsDefaultData() {
		return this.latestSaveAsDefaultData;
	}

	updateSaveAsDefaultData(data: any) {
		this.latestSaveAsDefaultData = data;
	}

	rgbToHex(rgb: any) {
		// Extracting the RGB components
		let hexColor: any = rgb;
		let rgbArray: any = rgb.match(/\d+/g);
		if (rgbArray) {
			let r: any = parseInt(rgbArray[0]);
			let g: any = parseInt(rgbArray[1]);
			let b: any = parseInt(rgbArray[2]);

			// Converting each component to hexadecimal
			let rHex: any = r.toString(16).padStart(2, '0');
			let gHex: any = g.toString(16).padStart(2, '0');
			let bHex: any = b.toString(16).padStart(2, '0');

			// Constructing the hexadecimal color code
			hexColor = '#' + rHex + gHex + bHex;

		}
		return hexColor;
	}

	getControlSaveAsObj() {
		return this.controlSaveAsObj;
	}

	updatedSaveAsDefaultSavedData(defaultControlProperty: any) {
		if (defaultControlProperty && defaultControlProperty.length) {
			defaultControlProperty.forEach((ele: any) => {
				this.updateDictionary(ele.Control.toLowerCase(), {
					saveAsDefault: true,
					required: ele.Values.Required ? ele.Values.Required : false,
					fontFamily: ele.Values.FontType ? ele.Values.FontType : 'Courier',
					fontWeight: ele.Values.Bold ? ele.Values.Bold : 'normal',
					textDecoration: ele.Values.Underline ? ele.Values.Underline : 'none',
					fontStyle: ele.Values.Italics ? ele.Values.Italics : 'normal',
					fontSize: ele.Values.FontSize ? ele.Values.FontSize : '12px',
					color: ele.Values.Color,
					textAlign: ele.Values.TextAlign ? ele.Values.TextAlign : 'left',
					lineHeight: (ele.Control.toLowerCase() === 'date') ? '' : ele.Values.FontSize,
					validationType: (ele.Control.toLowerCase() === 'text') ? ele.Values.ValidationType : '',
					adlnName: (ele.Control.toLowerCase() === 'text') ? ele.Values.AdlnName : '',
					adlnOption: (ele.Control.toLowerCase() === 'text') ? ele.Values.AdlnOption : '',
					preSelected: ele.Values.PreSelected ? ele.Values.PreSelected : false,
					checkboxGroup: ele.Values.CheckboxGroup ? ele.Values.CheckboxGroup : false,
					checkboxGroupName: '',
					checkboxName: '',
					dateFormat: (ele.Control.toLowerCase() === 'date') ? ele.Values.DateFormat : 'mm/dd/yyyy',
					size: ele.Values.Size ? ele.Values.Size : 'standard',
					fontTypeMeasurement: ele.Values.FontTypeMeasurement,
					firstLineBlank: (ele.Control.toLowerCase() === 'dropdown') ? ele.Values.PreSelected : false,
					labelControlValue: (ele.Control.toLowerCase() === 'label') ? ele.Values.LabelControlValue : ''
				})
			});
		}
	}

	saveAsDefaultSaveSettings(userDetails: any, ctrlsObj: any) {
		let SettingDetails: any = {};
		SettingDetails["02E3C230-7F06-4ED3-8B03-F9EB40D6F80B"] = {
			'ShowOnSettingsTab': "2",
			'SettingValue': JSON.stringify(ctrlsObj),
			'IsLock': false,
			'IsOverride': false,
			'IsLockChanged': false,
		}
		let apiSettings: any = {
			"SettingsFor": "00000000-0000-0000-0000-000000000000",
			"UserEmail": userDetails.emailID,
			"SettingsForType": "User",
			"SettingDetails": SettingDetails,
			"TabId": 0,
			"CompanySettings": {}
		};

		let SettingsPrepareModal: any = {
			userSettingsData: JSON.stringify(apiSettings),
			userId: userDetails.userID
		};

		return SettingsPrepareModal;
	}

}

<div class="accordion mt-11r" id="accordionExample1">
    <div class="accordion-item">
        <div class="modal-header d-flex align-items-center justify-content-between pt-3">
            <div class="d-flex align-items-center">
                <h5 class="modal-title font-w600 " id="staticBackdropLabel">Dependencies:</h5>
                <span *ngIf="!isViewAll" class="font14 top2 ms-1">Specify how an action on the controlling field determines the behavior of
                    the
                    dependent field.</span>
            </div>
            <div class="d-flex align-items-center">
                <button style="margin-right: 3px;" *ngIf="!isViewAll" class="btn viewdraft add-btn" title="Add Row"
                    (click)="addAdditionalDependentRow(1)">
                    <i aria-hidden="true" class="material-icons font20 me-1">add</i> Add Row
                </button>
                <span class="br-nav" *ngIf="!isViewAll">|</span>
                <h2 class="accordion-header" id="dependence">
                    <button id="depencieclose" title="Hide/Show" class="accordion-button" type="button" data-bs-toggle="collapse"
                        data-bs-target="#collapseDependence" aria-expanded="true" aria-controls="collapseDependence">
                    </button>
                </h2>
                <span class="br-nav">|</span>
                <div class="modalposition" aria-label="Close" (click)="closeDependecies()"> <span
                        class="material-icons model-closeicon">close</span>
                </div>
            </div>

        </div>
        <div class="modal-body py-1" style="padding-right: 5px;">
            <div id="collapseDependence" class="accordion-collapse collapse show" aria-labelledby="dependence"
                data-bs-parent="#accordionExample1">
                <div class="accordion-body py-0 pe-2">
                    <!----------------------------Table started --------------------------->
                    <div   class="row mb-1 custom-scroll">
                        <div style="display: block;" class=" divAddDependency col-md-3 pe-0">
                            <table class="table mb-0">
                                <tr class="tablehead1">
                                    <th class="">Select a controlling field</th>
                                </tr>
                                <tr>
                                    <td class="" style="padding-right: 5px;padding-top: 3px;">
                                        <select id="slControllingFieldSelectList" onfocus='this.size=4;'
                                            onblur='this.size=1;' onchange='this.size=1; this.blur();'
                                            class="form-select controlshadow controllingFieldSelectList"
                                            aria-label="Default select example"
                                            (change)="updateAllDependentDropdowns()">
                                            <option value="0" [selected]="isDefaultSelected == true ?'selected':''">
                                                Select</option>
                                            <option *ngFor="let eve of controllingFieldSelectList" title="{{eve.title}}"
                                                [selected]="eve.isSelected == true ?'selected':''"
                                                value="{{eve.value}}">
                                                {{eve.text}}
                                            </option>
                                        </select>
                                    </td>
                                </tr>
                            </table>
                        </div>
                        <div style="display: block;" class=" divAddDependency col-md-9 ps-0">
                            <table class="table mb-0">
                                <tr class="tablehead1">
                                    <th class="">Select its action </th>
                                    <th class="">Select dependent field </th>
                                    <th class="">Select its behaviour</th>
                                    <!-- <th class="w-20"></th> -->
                                    <th colspan="2"></th>
                                    <th id="thAddTextField" class=" " colspan="2" style="display: none;"></th>
                                </tr>

                                <tr class="controlshadow"
                                    *ngFor="let rowItem of appendOtherDependentRowsList; let i=index;">
                                    <td class="">
                                        <select id="slDependentConditionsSelectList_{{i}}"
                                            class="form-select controlshadow dependentConditionsSelectList w-100"
                                            aria-label="Default select example" (change)="addTextField($event,i)">
                                            <option *ngFor="let eve of controllingdependentConditionsSelectList"
                                                [disabled]="eve.isDisabled == true ?'disabled':''"
                                                [selected]="eve.isSelected == true ?'selected':''" title="{{eve.title}}"
                                                value="{{eve.value}}">{{eve.text}}</option>
                                        </select>
                                    </td>
                                    <td class="">
                                        <select id="slDependentFieldSelectList_{{i}}"
                                            class="form-select controlshadow dependentFieldSelectList"
                                            aria-label="Default select example"
                                            (change)="checkIsAlreadyAddedAsDependent(i,0)">
                                            <option value="0">Select</option>
                                            <option *ngFor="let eve of controllingDependentFieldSelectList"
                                                [selected]="eve.isSelected == true ?'selected':''" title="{{eve.title}}"
                                                value="{{eve.value}}">{{eve.text}}</option>
                                        </select>
                                    </td>
                                    <td class="">
                                        <select id="slDependentActionSelectList_{{i}}"
                                            class="form-select controlshadow dependentActionSelectList w-90"
                                            aria-label="Default select example">
                                            <option *ngFor="let eve of controllingDependentActionSelectList"
                                                [selected]="eve.isSelected == true ?'selected':''" title="{{eve.title}}"
                                                value="{{eve.value}}">{{eve.text}}</option>
                                        </select>
                                    </td>
                                    <td id="tdAddTextField_{{i}}" class="">
                                        <input type="text" id="txtTextField_{{i}}" value="" class="form-control" autocomplete="off" spellcheck="false"
                                            placeholder="">
                                    </td>
                                    <td id="tdDeleteRow_{{i}}" class="" title="Delete Row">
                                        <input type="hidden" id="hdnDelete_{{i}}" value ="{{rowItem.rIndex}}" />
                                        <i class="fa fa-minus-square-o font20 text-danger mt-1" aria-hidden="true"
                                            (click)="deleteAdditionalDependentRow(i,rowItem.rIndex)"></i>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>

                    <div id="divViewAll"  style="display: none;" class="table-responsive custom-scroll">
                        <table id="divViewAllTable" class="table table-space documentTable" style="margin-bottom: 1px;">
                            <thead>
                                <tr class="th-bg">
                                    <th class="th-bg ps-2">Controlling field </th>
                                    <th class="th-bg ps-2">Action </th>
                                    <th class="th-bg ps-2">Dependent field</th>
                                    <th colspan="2" class="th-bg ps-2">Behaviour</th>
                                    <th class="th-bg" style="display: none;"></th>
                                </tr>
                            </thead>
                            <tbody id="tableViewAllBody" class="tableViewAllBody">

                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal-footer justify-content-between py-2">
            <div *ngIf="isViewAll == false">
                <button class="btn view-btn" title="View all dependencies" (click)="viewAllDependecies()">
                    <span class="material-icons me-1">
                        visibility </span> View All</button>
            </div>
            <div class="d-flex align-items-center" *ngIf="isViewAll == false">
                <button id="cancelDependencyBtn" class="btn back-btn cancelbtn form-btn" (click)="closeDependecies()">
                    Cancel</button>
                <button id="saveDependencyBtn" role="button" class="okbtn btn back-btn form-btn ms-3 saveDependencyBtn"
                    (click)="saveDependentFields()">
                    Save</button>
            </div>

            <div class="d-flex align-items-center justify-content-end w-100" *ngIf="isViewAll != false">
                <button class="btn back-btn cancelbtn form-btn" title="Back to add dependencies"
                    (click)="showDependenciesPopUp()">
                    <span class="fa fa-arrow-left me-2"></span> Back</button>
            </div>
        </div>
    </div>
</div>

<div *ngIf="showLoader" class="loader">
</div>
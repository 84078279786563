import { Component, EventEmitter, HostListener, Output } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { ApiUrlConfig, NavigateUrlConfig } from 'src/app/constants/common-apiurls';
import { CommonConstants } from 'src/app/constants/common-constants';
import { ResourceKeys } from 'src/app/constants/resourcekeys';
import { APIService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth.service';
import { CommonService } from 'src/app/services/common.service';
import { NavigationService } from 'src/app/services/navigation.service';
import { SessionStorageService } from 'src/app/services/session-storage.service';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-discard-envelope',
    templateUrl: './discard-envelope.component.html',
    styleUrls: ['./discard-envelope.component.scss']
})
export class DiscardEnvelopeComponent {
    resourceKeys = ResourceKeys;
    @Output() discardEnvelopChangesMade: EventEmitter<any> = new EventEmitter();
    showLoader: boolean = false;
    public onCheckDiscardEnvelope: EventEmitter<any> = new EventEmitter();
    hostListenerStatus: any = false;

    constructor(
        public modalRef: BsModalRef,
        private toastrService: ToastrService,
        private authService: AuthService,
        private commonService: CommonService,
        private apiService: APIService,
        private navigationService: NavigationService,
        private sessionStorageService: SessionStorageService,
        private router: Router,
    ) { }

    ngOnInit(): void {
    }

    @HostListener('window:unload', ['$event'])
    unloadHandler(event: Event) {
        this.PostCall(event);
    }

    PostCall(event: any) {
        //event.preventDefault();
    }

    @HostListener('window:beforeunload', ['$event'])
    beforeUnloadHander(event: Event) {
        let ClickedSignMeOut: any = this.sessionStorageService.getClickedSignMeOut();
        if (this.hostListenerStatus == true || ClickedSignMeOut == "true") {
            this.PostCall(event);
            return true;
        }
        else {
            return false;
        }
    }

    onDiscardEnvelope() {
        let popUpStatus = {
            status: 'ok'
        };
        this.onCheckDiscardEnvelope.next(popUpStatus);
        let getIntializePrepareDetails: any = this.sessionStorageService.getIntializePrepareDetails();
        if (this.authService.isTokenExists() == "false") this.commonService.redirectToMainApp(getIntializePrepareDetails.SourceURL);
        else {
            let getUserDetails: any = this.authService.getDetails();
            if (getUserDetails) {
                let envelopeDetail: any = JSON.parse(getUserDetails);
                if (envelopeDetail) {
                    this.showLoader = true;
                    let apiUrl = ApiUrlConfig.DiscardEnvelope + "?envelopeId=" + envelopeDetail.envelopeOrTemplateId;
                    this.apiService.delete(apiUrl).subscribe((resp: any) => {
                        this.showLoader = false;
                        if (resp && resp.statusCode == 200) {
                            this.modalRef.hide();
                            this.showToastrSuccess(resp.message);
                            setTimeout(() => {
                                let rsignUrl: any = environment.rsignUrl;
                                if (getIntializePrepareDetails && getIntializePrepareDetails != null && getIntializePrepareDetails.SourceUrlType && getIntializePrepareDetails.SourceUrlType != "") {
                                    rsignUrl = this.commonService.generateDomainRediRectURL(getIntializePrepareDetails.SourceUrlType);
                                }
                                this.navigationService.redirect(rsignUrl + NavigateUrlConfig.Home);
                            }, 1000);
                        }
                        else if(resp && resp.originalError && resp.originalError.statusText && resp.originalError.statusText == "Unauthorized") {
                            this.redirectRSignLogOnPage();
                        }
                        else {
                            if (resp.message != null && resp.message != '')
                                this.showToastrWarning(resp.message);
                            else
                                this.showToastrWarning(CommonConstants.DiscardEnvelopeFailed);
                        }
                    });
                }
                else {
                    this.showToastrWarning(CommonConstants.DiscardEnvelopeFailed);
                }
            }
            else {
                this.showToastrWarning(CommonConstants.DiscardEnvelopeFailed);
            }
        }
    }

    onDiscardCancel() {
        let popUpStatus = {
            status: 'cancel'
        };
        this.onCheckDiscardEnvelope.next(popUpStatus);
        this.modalRef.hide();
    }

    getLanguageTranslationValue(resourcekeyId: any) {
        return this.commonService.getLabelTranslationVal(resourcekeyId);
    }

    showToastrWarning(msg: any) {
        this.toastrService.warning(msg, 'Warning');
    }

    showToastrSuccess(message: any) {
        this.toastrService.success(message, 'Success');
    }

    redirectRSignLogOnPage(){
        this.hostListenerStatus = true;
        this.showLoader = false;
        this.toastrService.info(CommonConstants.SessionTimedOutMsg, 'Info');
        this.sessionStorageService.setIntializePrepareDetails('EnvelopeID', '');
        this.sessionStorageService.setIntializePrepareDetails('EmailId', '');
        this.sessionStorageService.setIntializePrepareDetails('PageName', '');

        let getIntializePrepareDetails: any = this.sessionStorageService.getIntializePrepareDetails();
        if (getIntializePrepareDetails && getIntializePrepareDetails != null && getIntializePrepareDetails.SourceUrlType && getIntializePrepareDetails.SourceUrlType != "") {
            let sourceUrl: any = this.commonService.generateDomainRediRectURL(getIntializePrepareDetails.SourceUrlType);
            window.location.href = sourceUrl + NavigateUrlConfig.LogOn;
        }
        else {
            this.router.navigate(['/global-error']);
        }
    }
}

import { Component, Input } from '@angular/core';
import { CommonService } from '../../services/common.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import * as $ from 'jquery';
import { TranslationService } from 'src/app/services/translation.service';
import { ResourceKeys } from 'src/app/constants/resourcekeys';
import { SessionStorageService } from '../../services/session-storage.service';
interface DocumentDetail {
	id: number;
	path: string;
	docPageNo: number;
	pageNo: number;
	dimension: {
		height: number;
		width: number;
	};
	document: {
		id: string;
		name: string;
	};
	isTemplateDatedBeforePortraitLandscapeFeature: boolean;
	isActive: boolean;
	controlsCount: number;
}

interface GroupedDocument {
	name: string;
	totalCount: number;
	details: DocumentDetail[];
}

@Component({
	selector: 'app-preview-view',
	templateUrl: './preview-view.component.html',
	styleUrls: ['./preview-view.component.scss']
})
export class PreviewViewComponent {
	resourceKeys = ResourceKeys;
	private ngUnsubscribe = new Subject();
	previewObject: any = {};
	@Input() previewObj: any;
	groupedByDocumentName: any;
	groupedDocuments: GroupedDocument[] = [];
	currentIndex: any = 0;

	constructor(
		private commonService: CommonService,
		private translationService: TranslationService,
		private sessionStorageService: SessionStorageService
	) {
		this.commonService.previewObject$
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe((updatedObject) => {
				// Handle the updated object
				this.previewObject = updatedObject;
				// this.resetGroupedDocumentsCount();
				// for(let i=0;i<this.previewObject.tempTotalCountPerPage.length;i++){
				// 	for(let j=0;j<this.groupedDocuments.length;j++){
				// 		if(this.previewObject.tempTotalCountPerPage[i].documentId ===  this.groupedDocuments[j].details[0].document.id){
				// 			this.groupedDocuments[j].totalCount += this.previewObject.tempTotalCountPerPage[i].controlsCount;
				// 			break;
				// 		}
				// 	}
				// }
			});

		this.commonService.callMultiDocumentsPreview$
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe((imgArr:any) => {
				let documents: any = imgArr;
				this.previewObject.documentList = imgArr;
				// this.updateMultiDocumentsPreviewData(documents);
			});

	}

	ngOnInit() {
	}

	onPreviewImage(prevObj:any, id:any){
		// this.commonService.updatePrevObject({
		// 	'totalControls': this.previewObject.totalControls,
		// 	'pageNumber': parseInt(id),
		// 	'documentList': this.previewObject.documentList
		// });

		if (id !== undefined) {
			let firstPageElement:any = $('img.imgControl[page-no="1"]');
			let firstPageNoAttr:any = firstPageElement.offset()?.top;
			let pageNoAttr:any = 0;
			let pageNoElement:any = $('img.imgControl[page-no="' + id + '"]');
			pageNoAttr = pageNoElement.offset()?.top;

			if (firstPageNoAttr !== undefined && pageNoAttr !== undefined) {
				let scrollTo:any = Math.abs(pageNoAttr - firstPageNoAttr) - 20;
				$('.docImages').animate({ scrollTop: scrollTo }, 'slow');
			}
		}
	}

	getLanguageTranslationValue(resourcekeyId: any) {
		//return this.translationService.getLanguageTranslationVal(resourcekeyId);
		return this.commonService.getLabelTranslationVal(resourcekeyId);
		// return this.styleService.getCtrlDisplayStyle(controlingFieldId, '', 'signer');
	}

	ngOnDestroy(): void {
		this.ngUnsubscribe.complete();
	}

	updateMultiDocumentsPreviewData(documents:any){
		// let allimagesLoaded:any = localStorage.getItem('AllImagesProcessed');
		let allimagesLoaded:any = this.sessionStorageService.getAllImagesProcessed();
		if(allimagesLoaded === 'true' || allimagesLoaded === true){
			console.log('allimagesLoaded', allimagesLoaded);
			if(documents && documents.length > 0){
				this.groupedDocuments = this.transformDocuments(documents);
			}
		}
	}

	transformDocuments(documents: DocumentDetail[]): GroupedDocument[] {
		// Group documents by their name and accumulate controlsCount
		const grouped: { [key: string]: { totalCount: number, details: DocumentDetail[] } } = {};

		documents.forEach(doc => {
			const name = doc.document.name;
			if (!grouped[name]) {
				grouped[name] = { totalCount: 0, details: [] };
			}
			grouped[name].details.push(doc);
			grouped[name].totalCount += doc.controlsCount;
		});

		// Create an array of grouped documents with sequential IDs
		return Object.keys(grouped).map(name => {
			const { details, totalCount } = grouped[name];

			// Ensure IDs are sequential within each group
			details.sort((a, b) => a.id - b.id);

			// Generate new IDs if needed, or just sort by existing IDs
			details.forEach((detail, index) => {
				detail.id = index + 1; // Adjust ID to be sequential within the group
			});

			return {
				name,
				totalCount,  // Ensure totalCount is included
				details
			};
		});
	}

	// total document pages count method
	getPageCount(pages: DocumentDetail[]): number {
		return pages.length;
	}

	expand(index: any) {
		if (this.currentIndex === index) {
			this.currentIndex = null;
			return;
		}
		this.currentIndex = index;
	}

	// Method to calculate the page number for the current document
	getPageNumber(index: number, prevInd: number): number {
		let pageNumber = 1;
		for (let i = 0; i < index; i++) {
			pageNumber += this.groupedDocuments[i].details.length;
		}
		return pageNumber + prevInd;
	}

	resetGroupedDocumentsCount(){
		this.groupedDocuments.forEach(doc => {
			doc.totalCount = 0;
		});
	}

}

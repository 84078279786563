import { Injectable } from '@angular/core';
import { Control } from '../constants/common-constants';

interface Action {
    // Define the shape of your action
    type: string;
    payload: any;
}

@Injectable({
    providedIn: 'root'
})
export class UndoRedoStackService {

    private undoStack: Action[] = [];
    private redoStack: Action[] = [];

    constructor() { }

    push(action: Action) {
        this.undoStack.push(action);
    }

    undo(): Action | null {
        const action = this.undoStack.pop();
        if (action) {
            this.redoStack.push(action);
            return action;
        }
        return null; // Return null if no action
    }

    redo(): Action | null {
        const action = this.redoStack.pop();
        if (action) {
            this.undoStack.push(action);
            return action;
        }
        return null; // Return null if no action
    }

    // canUndo(): boolean {
    //     return this.undoStack.length > 0;
    // }

    // canRedo(): boolean {
    //     return this.redoStack.length > 0;
    // }

    redoCtrlResetCtrlBgs(action:any, isEnvelopeEditable:any){
        for(let i= 0;i<action.payload.length;i++){
            let ele: any = document.getElementById(action.payload[i].id);
            let resizableEle: any = document.getElementById('resizable_' + action.payload[i].id);
            if (ele && resizableEle) {
                let controlName:any = ele.getAttribute('data-controlname');
                this.updateCtrlBgColors(ele, resizableEle, controlName);
                resizableEle.classList.remove('control-active');
                if (isEnvelopeEditable) {
                    resizableEle.classList.remove('control-active-bg-color');
                }
                resizableEle.style.display = 'block';
            }
        }
    }

    resetBgColorAndActiveClass(id:any, isEnvelopeEditable:any, displayType:any){
        let ele: any = document.getElementById(id);
        let resizableEle: any = document.getElementById('resizable_' +id);
        if (ele && resizableEle) {
            if (resizableEle) {
                let controlName:any = ele.getAttribute('data-controlname');
                this.updateCtrlBgColors(ele, resizableEle, controlName);

                resizableEle.classList.remove('control-active');
                if (isEnvelopeEditable) {
                    resizableEle.classList.remove('control-active-bg-color');
                }
            }
            resizableEle.style.display = displayType;
        }
    }

    updateCtrlBgColors(ele:any, resizableEle:any, controlName:any){
        let attr_data_guid: any = resizableEle.getAttribute('data-guid');
        if (controlName === 'DateTimeStamp' || controlName === 'Label' || controlName === 'Hyperlink') {
            resizableEle.style.background = '#ffff00';
            if(controlName === 'Hyperlink'){
                resizableEle.style.outline = 'none';
            }
        }
        else if (attr_data_guid.toLowerCase() == Control.DropDown.toLowerCase()) {
            let spnSelectEle: any = document.getElementById('spn' + ele.id);
            spnSelectEle.classList.remove('mouse-selected');

            let isParentPresent: any = '', isChildPresent: any = '';
            isParentPresent = resizableEle.getAttribute('is-parent-present');
            isChildPresent = resizableEle.getAttribute('is-child-present');
            if (isParentPresent == 'false' || isChildPresent == 'false') {
                resizableEle.style.background = '#ffffff';
            }
        }
        else if (attr_data_guid.toLowerCase() == Control.Radio.toLowerCase() || attr_data_guid.toLowerCase() == Control.Checkbox.toLowerCase()) {
            let spnresizable: any = document.getElementById(ele.id);
            if (spnresizable.getAttribute('data-selected1') != 'true') {
                spnresizable.style.background = '#ffffff';
            }
        }
        else {
            resizableEle.style.background = '#ffffff';
        }
    }
}

<div class="side-navbar d-flex justify-content-between flex-wrap flex-column w-16" id="sidebar">
    <div class="mb-0">
        <div class="d-flex flex-column mt-3 px-3 document-name" tabindex="-1">
            <h6 class="font-w600 doc-head" style="width: 160px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;"
                title="{{previewObj?.documentName}}">
                <img class="h-18p" src="../../../assets/images/icons/Sample-Document.svg" alt="image" />
                    {{previewObj?.documentName}}
            </h6>
            <p class="d-flex align-items-center justify-content-between mb-1 font13">
                <span *ngIf="previewObject && previewObject.documentList"> {{getLanguageTranslationValue(resourceKeys.Page)}}: {{previewObject.documentList.length}}</span>
                <span>{{getLanguageTranslationValue(resourceKeys.Fields)}}: {{previewObject.totalControls}}</span>
            </p>
        </div>
        <div class="right-scrollbar">
            <ul class="nav flex-column force-overflow">
                <li *ngFor="let prev of previewObject.documentList;let prevInd = index;"
                    class="nav-link p-0 mb-2" (click)="onPreviewImage(prev, prevInd+1)">
                    <div class="mt-2 d-flex flex-column align-items-center"
                        [ngClass]="{ active: prevInd+1 === previewObject.pageNumber}">
                        <div class="thumbnail" id="rotate{{prevInd+1}}">
                            <div class="thumbnails-page-btn"
                                style="position: relative; top: 174px; left: 2px;" id="pageCountColor{{prevInd+1}}">
                                <span class="pagenum">{{prevInd+1}}</span>
                                <!-- <div class="d-flex align-items-center pre-icons">
                                    <span class="material-icons font20">
                                        crop_rotate
                                    </span>
                                    <span class="material-icons font20 ms-2">delete_outline
                                    </span>
                                </div> -->
                            </div>
                            <div class="d-flex justify-content-center ">
                                <div class="page-box">
                                    <img class="preview-img" src="{{prev.path}}" alt="image" />
                                </div>
                            </div>
                            <span *ngIf="prev.isActive" class="material-icons badge-icon" title="Controls placed on the document">
                                filter_frames
                            </span>
                        </div>
                    </div>
                </li>
            </ul>
        </div>

        <!---------- Multi document design --------->
        <div class="accordion d-none">
            <div class="preview-scrollbar">
                <div *ngFor="let group of groupedDocuments; let i = index;"
                    class="w-100 accordain-overflow style-scrollbar chekListAccordainClass"
                    id="documentAccordain_{{i}}">
                    <h2 class="accordion-header" id="documentHeading{{i+1}}">
                        <button [ngClass]="{ 'active1' : currentIndex === i }"
                            class="accordion1 border-boxsize"
                            (click)="expand(i)" style="display:flex;justify-content: space-around;">
                            <i class="fa fa-file-text-o me-2 font-bold" aria-hidden="true"></i>
                            <div style="width: 125px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;"
                                title="{{group.name}}">
                                {{ group.name }}
                            </div>
                        </button>
                    </h2>
                    <div class="divDocumentAccordain_{{i}}" [hidden]="currentIndex != i">
                        <p class="d-flex align-items-center justify-content-between mb-0 font13" style="margin:5px 10px;">
                            <span *ngIf="group.details">
                                {{getLanguageTranslationValue(resourceKeys.Page)}}: {{ getPageCount(group.details)
                                }}
                            </span>
                            <span>
                                {{getLanguageTranslationValue(resourceKeys.Fields)}}: {{group.totalCount}}
                            </span>
                        </p>
                        <ul class="nav flex-column force-overflow" style="margin-bottom:25px;margin-top: -12px;">
                            <li *ngFor="let prev of group.details; let prevInd = index;" class="nav-link p-0 mb-2"
                                (click)="onPreviewImage(prev, getPageNumber(i, prevInd))">
                                <div class="d-flex flex-column align-items-center"
                                    [ngClass]="{ active: getPageNumber(i, prevInd) === previewObject.pageNumber }">
                                    <div class="thumbnail" [id]="'rotate' + (prevInd + 1)">
                                        <div class="thumbnails-page-btn"
                                            style="position: relative; top: 174px; left: 2px;"
                                            [id]="'pageCountColor' + (prevInd + 1)">
                                            <span class="pagenum">{{ getPageNumber(i, prevInd) }}</span>
                                        </div>
                                        <div class="d-flex justify-content-center">
                                            <div class="page-box">
                                                <img class="preview-img" [src]="prev.path" alt="image" />
                                            </div>
                                        </div>
                                        <span *ngIf="prev.isActive" class="material-icons badge-icon" title="Controls placed on the document">
                                            filter_frames
                                        </span>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>

            </div>
        </div>

    </div>
</div>
